<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <h1>Nuevo Examen Médico</h1>
          </template>

          <template #end> </template>
        </Toolbar>
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  :options="oListCountry"
                  optionLabel="Name"
                  optionValue="CountryId"
                  placeholder="País"
                  class="w-full"
                />
                <label for="lblUnidadMedida">Tipo de perfil</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-8"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <InputText id="txtProductoId" type="hidden" />
                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                <label for="lblCodigoProducto">Código</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                <label for="lblCodigoProducto">Nombre</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-8">
              <FloatLabel>
                <Textarea id="txtReferencia" rows="2" cols="30" />
                <label for="lblReferencia">Descripción</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="selectedTimeValidity"
                  :options="timesValidity"
                  optionLabel="name"
                  placeholder=""
                  class="w-full"
                />
                <label for="lblUnidadMedida">Tiempo de Vigencia</label>
              </FloatLabel>
            </div>
            <!-- <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" 
                                    inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                    :class="{ 'p-invalid' : fechaFinValido }" />
                                <label for="lblFechaFin">Vigencia</label>
                            </FloatLabel>
                        </div> -->
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="selectedTimeRenewal"
                  :options="timesRenewal"
                  optionLabel="name"
                  placeholder=""
                  class="w-full"
                />
                <label for="lblUnidadMedida">Tiempo para Renovaciòn</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <div class="flex justify-content">
                <Checkbox v-model="checked" :binary="true" />
                <label for="ingredient4" class="ml-2">
                  Tiene Pre-Requisitos
                </label>
              </div>
            </div>
            <div class="field col-12 md:col-8"></div>
          </div>
        </div>

        <div v-if="checked">
          <Divider layout="horizontal" />
          <h5>Pre-Requisitos</h5>
          <div class="flex flex-column h-500rem mt-5">
            <div class="p-fluid p-formgrid grid">
              <div class="field col-12 md:col-6"></div>
              <!-- <div class="field col-12 md:col-4">
                <FloatLabel>
                  <InputText
                    id="txtCodigoProducto"
                    v-model="nameReq"
                    autocomplete="off"
                  />
                  <label for="lblCodigoProducto">Nombre</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-2">
                <FloatLabel>
                  <Calendar
                    showIcon
                    iconDisplay="input"
                    inputId="dtpFechaFin"
                    dateFormat="dd/mm/yy"
                    :class="{ 'p-invalid': fechaFinValido }"
                  />
                  <label for="lblFechaFin">Vigencia</label>
                </FloatLabel>
              </div> -->
              <div class="field col-12 md:col-2">
                <Button
                  type="button"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-info mr-2"
                  @click="modalSearchPersonal = true"
                ></Button>
              </div>

              <div class="col-12 md:col-8">
                <DataTable
                  ref="dtDetalle"
                  :value="oListaDetalle"
                  dataKey="DetalleId"
                  :paginator="false"
                  :rows="10"
                  :rowsPerPageOptions="[10, 25, 50, 100]"
                  :loading="bCargando"
                  class="p-datatable-sm"
                >
                  <template #header>
                    <div
                      class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                    >
                      <h5 class="m-0">Detalle Requisitos</h5>
                    </div>
                  </template>

                  <Column field="Name" header="Nombre" :sortable="true">
                    <template #body="slotProps">
                      <b>{{ slotProps.data.Name }}</b
                      ><br />
                      {{ slotProps.data.Code }}
                    </template>
                  </Column>
                  <Column
                    field="Validity"
                    header="Vigencia"
                    :sortable="true"
                  ></Column>
                  <!--<Column field="Type" header="Control" :sortable="true">
                                    <template #body="slotProps">
                                        <span v-if="slotProps.data.Type == 1">
                                            <Checkbox :binary="true" disabled />
                                        </span>
                                        <span v-if="slotProps.data.Type == 2">
                                            <InputNumber autocomplete="off" inputId="locale-us" locale="en-ES" :minFractionDigits="2"  />
                                        </span>
                                        <span v-if="slotProps.data.Type == 3">
                                            <InputText readonly />
                                        </span>
                                    </template>
                                </Column>-->
                  <Column
                    style="text-align: center"
                    headerStyle="min-width:12rem;"
                  >
                    <template #body="slotProps">
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded mt-2 p-button-danger"
                        @click="QuitarDetalle(slotProps.data)"
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider layout="horizontal" />
        <div class="flex justify-content-start">
          <Button
            label="Guardar"
            icon="pi pi-save"
            severity="primary"
            iconPos="right"
            @click="Guardar()"
            :loading="bCargando"
          />
          <Button
            label="Atrás"
            icon="pi pi-arrow-left"
            class="ml-2"
            severity="secondary"
            @click="BackTo"
          ></Button>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar Emo" :style="{ width: '70rem' }">
            <SearchEMO @closeModal="closeModal" @sendList="AddPersonal" /> 
        </Dialog>

</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import SearchEMO from './SearchEMO/SearchEMO.vue';

const router = useRouter();
const bCargando = ref(false);
const nameReq = ref("");
const typeReq = ref(0);
const oListTipoRequisito = ref([
  { Id: 1, Name: "Check" },
  { Id: 2, Name: "Númerico" },
  { Id: 3, Name: "Texto" },
]);

const oListaDetalle = ref([
  {
    DetalleId: 1,
    Code: "R01",
    Name: "Operación de Equipo Liviano",
    Type: 1,
    TypeName: "Check",
    Validity: "01/12/2024",
  },
  {
    DetalleId: 2,
    Code: "R02",
    Name: "Operación de Equipo Pesado",
    Type: 2,
    TypeName: "Númerico",
    Validity: "01/12/2024",
  },
  {
    DetalleId: 3,
    Code: "R03",
    Name: "Trabajos en Altura Estructural> 1.8m",
    Type: 3,
    TypeName: "Texto",
    Validity: "01/12/2024",
  },
]);

const selectedTimeValidity = ref();
const timesValidity = ref([
  { name: "Una semana (7 días)", code: "NY" },
  { name: "Dos semanas (15 días)", code: "RM" },
  { name: "Un mes (30 días)", code: "LDN" },
  { name: "Tres meses (90 días)", code: "IST" },
  { name: "Un año", code: "PRS" },
  { name: "Dos años", code: "PRSW" },
]);

const selectedTimeRenewal = ref();
const timesRenewal = ref([
  { name: "Tres días", code: "IST" },
  { name: "Una semana (7 días)", code: "NY" },
  { name: "Dos semanas (15 días)", code: "RM" },
  { name: "Un mes (30 días)", code: "LDN" },
]);

const checked = ref(false);

const modalSearchPersonal = ref(false);



//#region Eventos
onMounted(() => {
  Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {};

const BackTo = () => {
  router.push({ path: `/portal/Emos/Mantenedor` });
};

const QuitarDetalle = () => {}

const closeModal = () => {
  modalSearchPersonal.value = false;
}
//#endregion
</script>