import { createRouter, createWebHashHistory } from 'vue-router'

import PasswordChangeView from '../views/PasswordChangeView'
import DashboardView from '../views/DashboardView.vue'
import PageNotFoundView from '../views/PageNotFoundView'
import InitView from '../views/InitView'
import AppLayout from '@/layout/AppLayout.vue';
import LoginView from '../views/LoginView';
import LandingView from '../views/LandingView';
import ModulesView from '../views/ModulesView';
import MantenedorUsuario from '../components/Usuario/MantenedorUsuario'

import { useStore } from "vuex";
import SeleccionProductoTipo from '@/components/Producto/SeleccionProductoTipo.vue'
import MantenedorProducto from '@/components/Producto/MantenedorProducto.vue'
import NuevoProducto from '@/components/Producto/NuevoProducto.vue'
import FichaAtencion from '@/components/FichaAtencion/FichaAtencion.vue'
import SeleccionProductoFicha from '@/components/FichaAtencion/SeleccionProductoFicha.vue'
import MantenedorCategoria from '@/components/Categoria/MantenedorCategoria.vue'
import ReportePago from '@/components/Reporte/ReportePago.vue'
import CompanyMaintance from '@/components/Company/CompanyMaintance.vue'
import CompanyNew from '@/components/Company/CompanyNew.vue'
import ServiceMaintance from '@/components/Service/ServiceMaintance.vue'
import ServiceNew from '@/components/Service/ServiceNew.vue'
import CourseMaintance from '@/components/Courses/CourseMaintance.vue'
import CourseNew from '@/components/Courses/CourseNew.vue'
import CertificateMaintance from '@/components/Certificate/CertificateMaintance.vue'
import CertificateNew from '@/components/Certificate/CertificateNew.vue'
import EmosMaintance from '@/components/Emos/EmosMaintance.vue'
import EmosNew from '@/components/Emos/EmosNew.vue'
import SelectionView from '@/views/SelectionView.vue'
import UserMaintance from '@/components/User/UserMaintance.vue'
import UserNew from '@/components/User/UserNew.vue'
import ServiceDetails from '@/components/Service/ServiceDetails.vue'
import ReportServiceEmployee from '@/components/Reports/Services/ReportServiceEmployee.vue'
import ReportServiceCourse from '@/components/Reports/Services/ReportServiceCourse.vue'
import ReportServiceAccredited from '@/components/Reports/Services/ReportServiceAccredited.vue'
import ReportServiceEmo from '@/components/Reports/Services/ReportServiceEmo.vue'
import ReportServiceGeneralCourse from '@/components/Reports/Services/ReportServiceGeneralCourse.vue'
import AccreditationPersonal from '@/components/Accreditation/AccreditationPersonal.vue'

const routes = [{
        path: '/web',
        component: LandingView
    },
    {
        path: '/',
        component: LoginView,
        name: 'Login'
    },
    {
      path: '/seleccion',
      component: SelectionView,
      name: 'Selection'
    },
    {
        path: '/portal',
        component: AppLayout,
        children: [
          {
                path: '/portal',
                component: InitView,
                name: "Init",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system-dashboard',
                component: DashboardView,
                name: "Dashboard",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/modules',
                component: ModulesView,
                meta: {
                    requiresAuthentication: true,
                }
            },            
            {
                path: '/system/change-password',
                component: PasswordChangeView,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
              path: '/portal/usuario',
              component: MantenedorUsuario,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/categoria',
              component: MantenedorCategoria,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/producto',
              component: SeleccionProductoTipo,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/mantenedorproducto/:id',
              component: MantenedorProducto,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/nuevoproducto/:tipoid/:id?',
              component: NuevoProducto,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/fichaatencion/:pacienteid/:productoid/:id?',
              component: FichaAtencion,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/fichaatencionseleccion/:pacienteid',
              component: SeleccionProductoFicha,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/reporte/pagos',
              component: ReportePago,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/security/user',
              component: UserMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/security/usernew/:userId?',
              component: UserNew,
              meta: {
                requiresAuthentication: true
              }
            },



            {
              path: '/portal/Empresa/Mantenedor',
              component: CompanyMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Empresa/Nuevo',
              component: CompanyNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Servicio/Mantenedor',
              component: ServiceMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Servicio/Nuevo',
              component: ServiceNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Cursos/Mantenedor',
              component: CourseMaintance,
              meta: {
                requiresAuthentication: true
              }
            },            
            {
              path: '/portal/Curso/Nuevo',
              component: CourseNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Certificaciones/Mantenedor',
              component: CertificateMaintance,
              meta: {
                requiresAuthentication: true
              }
            },            
            {
              path: '/portal/Certificado/Nuevo',
              component: CertificateNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Emos/Mantenedor',
              component: EmosMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Emos/Nuevo',
              component: EmosNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Servicio/Detalle/:id',
              component: ServiceDetails,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Employee',
              component: ReportServiceEmployee,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Course',
              component: ReportServiceCourse,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Accredited',
              component: ReportServiceAccredited,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Emo',
              component: ReportServiceEmo,
              meta: {
                requiresAuthentication: true
              }
            }, 
            {
              path: '/portal/Reports/GeneralCourse',
              component: ReportServiceGeneralCourse,
              meta: {
                requiresAuthentication: true
              }
            },           
            {
              path: '/portal/Accreditation/Personal',
              component: AccreditationPersonal,
              meta: {
                requiresAuthentication: true
              }
            },



            {
                path: "*",
                component: PageNotFoundView,
            }
        ]
    }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL), 
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let store = useStore();
  let dUser = JSON.parse(store.getters["isAuthenticated"]);
  const isAuthenticated = dUser;
  if (from.name !== "Login") {
    if (to.matched.some((record) => record.meta.requiresAuthentication)) {
      if (isAuthenticated) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      if (isAuthenticated && to.meta.disallowAuthed) {
        next({ name: "Init" });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
