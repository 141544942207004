<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nuevo Usuario</h1>
                    </template>

                    <template #end>
                    </template>
                </Toolbar>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Código</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">DNI</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtProductoId" type="hidden" />
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Nombres</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Apellidos</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Correo corporativo</label>
                            </FloatLabel>
                        </div>                        
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Número de contacto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListCountry" 
                                    optionLabel="Name" optionValue="CountryId" 
                                    placeholder="País" class="w-full"  />
                                <label for="lblUnidadMedida">Tipo usuario</label>
                            </FloatLabel>
                        </div>
                        <Divider layout="horizontal" />
                        <h4 class="field col-12 md:col-8">Permisos</h4>                        
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Administrador</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Editar</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>

                <Divider layout="horizontal" />
                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="Guardar()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const oListComprany = ref([
    { CompanyId: 1, Name: 'Empresa 1' },
    { CompanyId: 2, Name: 'Empresa 2' },
    { CompanyId: 3, Name: 'Empresa 3' },
]);

//#region Eventos
onMounted(() => {
    Initialize();
});

const UsrId = route.params.userId

//#endregion

//#region Metodos
const Initialize = () => {
    
} 

const BackTo = () => {
    router.push({ path: `/portal/security/user` });
}
//#endregion

</script>