<template>
    <div class="flex flex-column h-500rem">
        <div class="p-fluid p-formgrid grid mb-0 mt-0">
            <div class="field col-12 md:col-12 mb-1 pb-3">
                <h5>Reporte general de cursos</h5>
            </div>
            <div class="field col-12 md:col-2">
                <FloatLabel>
                    <Dropdown :options="oListAptitud" v-model="oReportParams.AptitudId"
                        optionLabel="Name" optionValue="AptitudId" 
                        placeholder="Perfil" class="w-full"  />
                    <label for="lblPerfil">Aptitud EMO</label>
                </FloatLabel>
            </div>
            <div class="field col-12 md:col-2">
                <FloatLabel>
                    <Dropdown :options="oListTypeEmployee" v-model="oReportParams.TypeEmployeeId"
                        optionLabel="Name" optionValue="TypeEmployeeId" 
                        placeholder="Tipo trabajador" class="w-full"  />
                    <label for="lblTipoTrabajador">Tipo trabajador</label>
                </FloatLabel>
            </div>
            <div class="field col-12 md:col-2">
                <FloatLabel>
                    <Dropdown :options="oListCondition" v-model="oReportParams.ConditionId"
                        optionLabel="Name" optionValue="ConditionId" 
                        placeholder="Tipo trabajador" class="w-full"  />
                    <label for="lblTipoTrabajador">Condición mina</label>
                </FloatLabel>
            </div>
            <div class="field col-12 md:col-1">
                <Button label="Buscar"  class="button-primary"/>
            </div>  
        </div>
    </div>
    
    <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
        <template #start>
            <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                      
        </div>
        </template>
    </Toolbar>
    <div class="m-0">
        <DataTable 
            :value="oListReport" 
            :rowHover="true"
            :loading="bCargando"
            class="p-datatable-sm"
        >
            <Column field="Profile" header="Estatus general de curso">
                <template #footer>
                    <strong class="grid-totals">Total</strong>
                </template>
            </Column>
            <Column field="Total" header="Total">
                <template #footer>
                    <span class="grid-totals">{{ total }}</span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const bCargando = ref(false);

const oReportParams = ref({
    AptitudId: 0,
    TypeEmployeeId: 0,
    ConditionId: 0
});

const oListAptitud = ref([
    { AptitudId: 0, Name: 'Todos'},
    { AptitudId: 1, Name: 'Perfil 1'}
]);

const oListTypeEmployee = ref([
    { TypeEmployeeId: 0, Name: 'Todos'},
    { TypeEmployeeId: 1, Name: 'Tipo trabajador 1'}
]);

const oListCondition = ref([
    { ConditionId: 0, Name: 'Todos'},
    { ConditionId: 1, Name: 'Condición 1'}
]);

const oListReport = ref([
    { ReportId: 0, Profile: '0', Total: 0 },
    { ReportId: 1, Profile: '1', Total: 0 },
    { ReportId: 2, Profile: '2', Total: 0 },
    { ReportId: 3, Profile: '3', Total: 0 },
]);

// Cálculo de los totales
const totalPayroll = computed(() => {
    return oListReport.value.reduce((sum, item) => sum + item.Payroll, 0);
});

const totalTemporary = computed(() => {
    return oListReport.value.reduce((sum, item) => sum + item.Temporary, 0);
});

const total = computed(() => {
    return oListReport.value.reduce((sum, item) => sum + item.Total, 0);
});

</script>