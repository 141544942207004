<template>
    <div class="card pt-3">
        <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
            <template #start>
                <div style="display: flex; align-items: flex-start;">
                    <div>
                        <h5 style="margin: 0;">Documentos adjuntos</h5>
                    </div>
                </div>
            </template>
        </Toolbar>
        <DataTable ref="dtPersonal" 
            :value="items"
            dataKey="PersonId"
            :rowHover="true"
            :loading="bCargando"
            :filters="filtros"
            class="p-datatable-sm"
            >
            <Column field="Index" header="#" headerStyle="width: 1rem"></Column>
            <Column field="Document" header="Documento" ></Column>
            <Column field="Status" header="Estado adjunto" bodyClass="text-left">
                <template #body="slotProps">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Status, 'pi-times-circle text-red-500': !slotProps.data.Status }"></i>
                </template>
            </Column>
            <Column field="Url" header="Visualización">
                <template #body="slotProps">
                    <Button v-if="slotProps.data.Status" icon="pi pi-eye" v-tooltip.top="'Ver archivo'" class="p-button-rounded p-button-secondary mr-2" 
                    @click="OpenDocument(slotProps.data)"/>
                    <Button v-if="!slotProps.data.Status" icon="pi pi-cloud-upload" v-tooltip.top="'Subir archivo'" outlined class="p-button-rounded p-button-danger mr-2" 
                    @click="UploadDocument(slotProps.data)"/>
                </template>
            </Column>
            <Column field="LastDate" header="Última modificación"></Column>
        </DataTable>
    </div>

    <Dialog v-model:visible="modalUploadDocument" modal header="Subir archivo" :style="{ width: '40rem' }">
        <span class="p-text-secondary block mb-5">Seleccione el archivo para el documento DNI.</span>
        <div class="row">
            <FileUpload 
                name="filename[]" 
                @uploader="UploadDocumentFile" 
                :multiple="true" 
                accept="image/*" 
                :maxFileSize="1000000"
                chooseLabel="Seleccionar"
                uploadLabel="Subir"
                cancelLabel="Cancelar"
                :fileLimit="1"
                invalidFileLimitMessage="Se superó el número máximo de archivos; el límite es 4 como máximo"
                customUpload
            >
                <template #empty>
                    <p>Arrastre y suelte archivos aquí para cargarlos.</p>
                </template>
            </FileUpload>
        </div>
        <div class="flex justify-content-end gap-2 mt-3">
            <Button type="button" label="Cerrar" severity="secondary" @click="modalUploadDocument = false"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const modalUploadDocument = ref(false);


//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

defineProps({
    items: {
        type: Array,
        Required: true
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenDocument = (item) => {
    window.open(item.Url, '_blank');
}

const UploadDocument = (item) => {
    modalUploadDocument.value = true;
}

const UploadDocumentFile = () => {}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
