<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado general</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPacientes" 
                    v-model:expandedRows="expandedRowsGeneral" 
                    :value="oListGeneralStatus"
                    dataKey="EmoId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column expander style="width: 5rem" />
                    <Column field="Emo" header="Exámen Médico"></Column>
                    <Column field="ExamPlace" header="Lugar EMO" ></Column>
                    <Column field="MedicalExamDate" header="Fecha EMO"></Column>
                    <Column field="ExpirationDate" header="F. vcto EMO"></Column>
                    <Column field="GeneralCondition" header="Estado General EMO">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.GeneralCondition" severity="info" />
                        </template>
                    </Column>
                    <Column field="SuitableUpTo" header="Apto hasta"></Column>
                    <Column field="StatusObservation" header="">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.StatusObservation, 'pi-times-circle text-red-500': !slotProps.data.StatusObservation }"></i>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.Observations.length > 0">
                            <DataTable :value="slotProps.data.Observations">
                                <Column field="MedicalObservation" header="Observaciones médicas" >
                                    <template #body="slotProps">
                                        <Tag :value="slotProps.data.MedicalObservation" severity="info" />
                                    </template>
                                </Column>
                                <Column field="DeadlineLift" header="Fecha límite levantar observaciones"></Column>
                                <Column field="ContactPerson" header="Persona contacto"></Column>
                                <Column field="ContactNumber" header="Número contacto"></Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>

            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado de vacunas</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtVaccines" 
                    v-model:expandedRows="expandedRows" 
                    :value="oListVaccinesStatus"
                    dataKey="VaccinesId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <Column expander style="width: 5rem" />
                    <Column field="Vaccines" header="Vacuna"></Column>

                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.Dose.length > 0">
                            <DataTable :value="slotProps.data.Dose">
                                <Column field="DoseNumber" header="Dósis"></Column>
                                <Column field="DoseDate" header="Fecha"></Column>
                                <Column field="Observation" header="Observación"></Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" />
        </div>

    </div>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListDocumentsPersonal = ref([]);
const oListGeneralStatus = ref([]);
const oListVaccinesStatus = ref([]);
const expandedRowsGeneral = ref({});
const expandedRows = ref({});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {
     
     oListGeneralStatus.value = [{
         EmoId: 1,
         Emo: 'Aptitup EMO IPSYCOM',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: true,
         Observations: [
         {
             MedicalObservation: 'Observado 1',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         },
         {
             MedicalObservation: 'Observado 10',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     },
     {
         EmoId: 2,
         Emo: 'Aptitup EMO Yanacocha',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: false,
         Observations: [
         {
             MedicalObservation: 'Observado 2',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     },
     {
         EmoId: 3,
         Emo: 'Trabajos en altura',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: true,
         Observations: [
         {
             MedicalObservation: 'Observado 3',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     },
     {
         EmoId: 4,
         Emo: 'Espacios confinados',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: false,
         Observations: [
         {
             MedicalObservation: 'Observado 4',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     },
     {
         EmoId: 5,
         Emo: 'En caliente c/soldadura',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: true,
         Observations: [
         {
             MedicalObservation: 'Observado 5',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     },
     {
         EmoId: 6,
         Emo: 'EMPO',
         ExamPlace: 'Control Vital',
         MedicalExamDate: '17/02/2024',
         ExpirationDate: '17/02/2024',
         GeneralCondition: 'Apto con restricciones',
         SuitableUpTo: '17/02/2024',
         EmoFitness: 'Vigente',
         EmoFitnessCompany: 'Vigente',
         StatusObservation: true,
         Observations: [
         {
             EmoId: 6,
             MedicalObservation: 'Observado 6',
             DeadlineLift: '17/02/2024',
             ContactPerson: 'Dany Rosmary Acosta Santamaria',
             ContactNumber: '949954875'
         }
         ]
     }];
 
     oListVaccinesStatus.value = [
         {
            VaccinesId: 1,
             Vaccines: 'Fiebre amarilla',
             Dose: [{
                 VaccinesId: 1,
                 DoseNumber: 'Dosis única',
                 DoseDate: '17/02/2024',
                 Observation: ''
             }]
         },
         {
            VaccinesId: 2,
             Vaccines: 'Hepatitis B',
             Dose: [
                 {
                     VaccinesId: 2,
                     DoseNumber: '1° Dosis',
                     DoseDate: '17/02/2024',
                     Observation: ''
                 },
                 {
                     VaccinesId: 2,
                     DoseNumber: '2° Dosis',
                     DoseDate: '17/03/2024',
                     Observation: ''
                 },
                 {
                     VaccinesId: 2,
                     DoseNumber: '3° Dosis',
                     DoseDate: '17/04/2024',
                     Observation: ''
                 }
             ]
         },
         {
             VaccinesId: 3,
             Vaccines: 'Difteria & Tetanos',
             Dose: [
                 {
                     VaccinesId: 3,
                     DoseNumber: '1° Dosis',
                     DoseDate: '17/02/2024',
                     Observation: ''
                 },
                 {
                    VaccinesId: 3,
                     DoseNumber: '2° Dosis',
                     DoseDate: '17/03/2024',
                     Observation: ''
                 },
                 {
                    VaccinesId: 3,
                     DoseNumber: '3° Dosis',
                     DoseDate: '17/04/2024',
                     Observation: ''
                 }
             ]
         },
         {
            VaccinesId: 4,
             Vaccines: 'Hepatitis A',
             Dose: [
                 {
                     VaccinesId: 4,
                     DoseNumber: '1° Dosis',
                     DoseDate: '17/02/2024',
                     Observation: ''
                 },
                 { 
                     VaccinesId: 4,
                     DoseNumber: '2° Dosis',
                     DoseDate: '17/03/2024',
                     Observation: 'En 6 meses'
                 }
             ]
         },
         {
             VaccinesId: 5,
             Vaccines: 'Fiebre Tifoidea',
             Dose: [
                 {
                     VaccinesId: 5, 
                     DoseNumber: '1° Dosis',
                     DoseDate: '17/02/2024',
                     Observation: ''
                 },
                 {
                    VaccinesId: 5,
                     DoseNumber: '2° Dosis',
                     DoseDate: '17/03/2024',
                     Observation: 'En 6 meses'
                 }
             ]
         }
     ];
     
    oListDocumentsPersonal.value = [
        {
            Index: 1,
            Document: 'APTITUD EMO IPSYCOM',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FDNI.pdf?alt=media&token=39517142-aa67-4f36-a1a2-190b4f3c4bfb',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 2,
            Document: 'APTITUD EMO YANACOCHA',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FHoja%20de%20Vida.pdf?alt=media&token=734674ea-826f-4183-844a-6f2f3805593c',
            LastDate: '01/12/2024',
            Status: true
        }
    ];
 }
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
