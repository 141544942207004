<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast/>
                <ConfirmDialog></ConfirmDialog>                
                <Toolbar class="mb-4">
                    <template #start>
                        <div class="my-2">
                            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="AbrirNuevoUsuario" />
                            <Button label="Inactivar" icon="pi pi-power-off" class="p-button-danger" />
                        </div>
                    </template>
                </Toolbar>
                <div v-if="bCargando">
                    <div class="progress-spinner-custom">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <DataTable ref="dtUsuarios" 
                    :value="oListaUsuarios"
                    v-model:selection="oListaUsuariosSeleccionados"
                    dataKey="UsuarioId"
                    :rowHover="true"
                    :paginator="true" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Usuarios</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="Usuario" header="Usuario" :sortable="true">
                    </Column>
                    <Column field="FechaExpiracion" header="Fecha Expiracion" 
                    :sortable="true" headerStyle="min-width:12rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">FechaExpiracion</span>
                        {{ slotProps.data.FechaExpiracion }}
                    </template>
                    </Column>
                    <Column field="Estado" header="Estado" :sortable="true">
                    <template #body="slotProps">
                        <span class="p-column-title">Estado</span>
                        <Tag :value="slotProps.data.Estado ? (slotProps.data.FechaExpiracion != null? 'Expirado': 'Activo') : 'Inactivo'" 
                        :severity="slotProps.data.Estado ? (slotProps.data.FechaExpiracion != null? 'danger': 'success')  : 'danger'" />
                    </template>
                    </Column>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2"
                            @click="EditarUsuario(slotProps.data)" />
                            <Button icon="pi pi-trash" v-tooltip.top="'Eliminar'" class="p-button-rounded p-button-warning mt-2"
                             />
                        </template>
                    </Column> 
                </DataTable>

                <Dialog v-model:visible="mostrarNuevoUsuario"
                    :style="{ width: '480px' }"
                    header="Nuevo Usuario" :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="lblIdUsuario">ID Usuario</label>
                        <InputNumber id="txtIdUsuario" 
                            v-model="usuarioEntidad.IdUsuario"
                            required="true" autofocus 
                         />
                    </div>
                    <div class="field">
                        <label for="lblUsuario">Usuario</label>
                        <InputText id="txtUsuario" 
                            v-model.trim="usuarioEntidad.Usuario"
                            :maxlength="150" required="true" autocomplete="off"
                         />
                        <small class="p-invalid" v-if="eventoSumbitted">Usuario es requerido.</small>
                    </div>
                    <div class="field">
                        <label for="lblClave">Clave</label>
                        <InputText id="txtClave" 
                            v-model.trim="usuarioEntidad.Clave"
                            :maxlength="150" required="true" autocomplete="off"
                         />
                        <small class="p-invalid" v-if="eventoSumbitted">Clave es requerido.</small>
                    </div>
                    <div class="field">
                        <Checkbox id="chkEstado" v-model="chkEstado" :binary="true" />
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="CerrarNuevoUsuario"/>
                        <Button label="Guardar" icon="pi pi-checks" class="p-button-success" @click="RegistrarUsuario" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import UsuarioService from '@/service/UsuarioService';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

//#region Variables
const bCargando = ref(false);
const oListaUsuarios = ref(null);
const oListaUsuariosSeleccionados = ref(null);
const filtros = ref({});
const usuarioEntidad = ref({});
const eventoSumbitted = ref(false);
const mostrarNuevoUsuario = ref(false);

const usuarioService = new UsuarioService();

const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const chkEstado = ref(false);
//#endregion


//#region Eventos
onMounted(() => {
    inicializador();
});
//#endregion

//#region Metodos
const inicializador = () => {
    bCargando.value = true;
    ListarUsuarios();
}

const ListarUsuarios = () => {
    usuarioService.ListarUsuariosService().then(
        response => {
            oListaUsuarios.value = response.Data;
            bCargando.value = false;
        }
    );
}

const AbrirNuevoUsuario = () => {
    usuarioEntidad.value = {};
    eventoSumbitted.value = false;
    mostrarNuevoUsuario.value = true;
}

const CerrarNuevoUsuario = () => {
    mostrarNuevoUsuario.value = false;
    eventoSumbitted.value = false;
    usuarioEntidad.value = {};
}

const EditarUsuario = (model) => {
    usuarioEntidad.value = { ...model };
    console.log(usuarioEntidad.value);
    mostrarNuevoUsuario.value = true;
}

const RegistrarUsuario = () => {
    eventoSumbitted.value = true;
    const { ...model } = usuarioEntidad.value;
    if(!model?.Usuario){
        toast.add({severity: 'warn', summary: 'Advertencia', detail: 'Por favor ingrese el usuario', life: 3000});
        return;
    }

    const usuarioStore = JSON.parse(store.state.datauser);
    
    const request = {
        IdPersona: 1,
        IdTipoUsuario: 1,
        UsuarioCreacion: usuarioStore.User.UsuarioId,
        FechaExpiracion: null,
        Estado: '1',
        ...model
    }

    confirm.require({
        message: '¿Está seguro de guardar los cambios?',
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        accept: async () => {
            if(request.IdUsuario == '0') {
                CrearEditarUsuario(request);
            }
        }
    });
}

const CrearEditarUsuario = (request) => {
    mostrarNuevoUsuario.value = false;
    bCargando.value = true;
    usuarioService.CrearUsuarioService(request).then(
        response => {
            console.log(response);
            
            if(response.Status == 201) {
                toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000});
            }
            else{
                toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000});
            }

            eventoSumbitted.value = false;
            bCargando.value = false;
            ListarUsuarios();
        }
    );
}

//#endregion

</script>