<template>
    <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>S/. 400.00</b></div>
                            <div class="font-medium text-sm">Gastos en Soles</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-file text-green-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>$ 120.50</b></div>
                            <div class="font-medium text-sm">Gastos en Dólares</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-user text-cyan-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>2</b></div>
                            <div class="font-medium text-sm">Acreditado</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-12 m-0 p-0">
        <div class="card">
            <DataTable ref="dtPersonal" 
                :value="oListPersonal"
                dataKey="PersonId"
                :rowHover="true"
                :loading="bCargando"
                :filters="filtros"
                class="p-datatable-sm"
                >

                <template #header>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Personal acreditado</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                        </span>
                    </div>
                </template>

                <Column style="text-align: center;" headerStyle="min-width:5rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                    @click="ViewAccreditationPersonal(slotProps.data)"/>
                    </template>
                </Column>
                <Column field="Index" header="#"></Column>
                <Column field="DocumentNumber" header="DNI" >
                    <template #body="slotProps">
                        <span v-tooltip.top="`Fecha Vencimiento:\n${ slotProps.data.ExpirationDate }`">{{ slotProps.data.DocumentNumber }}</span>
                    </template>
                </Column>
                <Column field="FullName" header="Nombre y Apellido">
                    <template #body="slotProps">
                        <Button :label="slotProps.data.FullName" link @click="ViewAccreditationPersonal(slotProps.data)" />
                    </template>
                </Column>
                <Column field="TypeEmployee" header="Tipo Contrato"></Column>
                <Column field="MinCondition" header="Tipo Ingreso"></Column>
                <Column field="PlaceOrigin" header="Lugar Origen"></Column>
                <Column field="Period" header="Período"></Column>
                <Column field="Status" header="Estado" bodyClass="text-center">
                    <template #body="slotProps">
                        <div class="centered-content">
                            <Tag v-if="slotProps.data.Status=='Disponible'" value="Disponible" severity="success" />
                            <Tag v-if="slotProps.data.Status=='Acreditado'" value="Acreditado" severity="info" />
                            <Tag v-if="slotProps.data.Status=='No disponible'" value="No disponible" severity="secondary" />
                            <Tag v-if="slotProps.data.Status=='Vigente'" value="Vigente" severity="warning" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        
        <OverlayPanel ref="op" appendTo="body">
            <DataTable :value="documents" selectionMode="single" >
                <Column field="Name" header="Documento" style="min-width: 12rem"></Column>
                <Column field="Status" header="Estado" bodyClass="text-center">
                    <template #body="slotProps">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Status, 'pi-times-circle text-red-500': !slotProps.data.Status }"></i>
                    </template>
                </Column>
            </DataTable>
        </OverlayPanel>

    </div>
</template>


<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListPersonalSelected = ref([]);

const op = ref();
const documents = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {
    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            PlaceOrigin: 'Trujillo',
            Period: 'Febrero 2024',
            Status: 'Acreditado'
        },
        {
            Index: 2,
            PersonId: 3,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            PlaceOrigin: 'Trujillo',
            Period: 'Febrero 2024',
            Status: 'Acreditado'
        }
    ];
}

const toggle = (event, olista) => {
    documents.value = olista;
    op.value.toggle(event);
};

//#endregion
</script>
