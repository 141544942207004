<template>
  <div class="layout-wrapper" :class="containerClass" @mousemove="resetTimer" @keydown="resetTimer">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view></router-view>
      </div>
      <app-footer></app-footer>
    </div>
    <app-config></app-config>
    <div class="layout-mask"></div>
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted, onBeforeMount } from "vue";
import AppTopbar from "./AppTopbar.vue";
import AppFooter from "./AppFooter.vue";
import AppSidebar from "./AppSidebar.vue";
import AppConfig from "./AppConfig.vue";
import { useLayout } from "@/layout/composables/layout";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import config from "@/config/config";

const store = useStore();
const router = useRouter();
const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

//Temporizador
 const tiempoInactividad = ref(null);
 const minutos = config.TiempoLimite;
 const tiempoLimite = (60000 * minutos);

onMounted( async () => {
    let userAux;
    let dataUserAux;

    dataUserAux = JSON.parse(store.state.datauser);
    if(dataUserAux){
      userAux = dataUserAux.user;
      userAux["Branch"] = 7;
      dataUserAux.User = userAux;
      store.commit('login',JSON.stringify(dataUserAux));
    }
    else{
      CerrarSesion();
    }

    ConfigurarListeners();
    IniciarTiempo();
});

onBeforeMount(() => {
  clearTimeout(tiempoInactividad.value);
  LimpiarListeners();
});

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const containerClass = computed(() => {
  return {
    "layout-theme-light": layoutConfig.darkTheme.value === "light",
    "layout-theme-dark": layoutConfig.darkTheme.value === "dark",
    "layout-overlay": layoutConfig.menuMode.value === "overlay",
    "layout-static": layoutConfig.menuMode.value === "static",
    "layout-static-inactive":
      layoutState.staticMenuDesktopInactive.value &&
      layoutConfig.menuMode.value === "static",
    "layout-overlay-active": layoutState.overlayMenuActive.value,
    "layout-mobile-active": layoutState.staticMenuMobileActive.value,
    "p-input-filled": layoutConfig.inputStyle.value === "filled",
    "p-ripple-disabled": !layoutConfig.ripple.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};

const isOutsideClicked = (event) => {
  const sidebarEl = document.querySelector(".layout-sidebar");
  const topbarEl = document.querySelector(".layout-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const IniciarTiempo = () => {
  tiempoInactividad.value = setTimeout(() => {
    CerrarSesion();
  }, tiempoLimite);
}

const ReiniciarTiempo = () => {
  clearTimeout(tiempoInactividad.value);
  IniciarTiempo();
}

const ConfigurarListeners = () => {
  window.addEventListener('mousemove', ReiniciarTiempo);
  window.addEventListener('keydown', ReiniciarTiempo);
}

const LimpiarListeners = () => {
  window.removeEventListener('mousemove', ReiniciarTiempo);
  window.removeEventListener('keydown', ReiniciarTiempo);
}

const CerrarSesion = () => {
  store.commit("logout");
  router.push({ path: "/" });
}
</script>

<style lang="scss" scoped></style>
