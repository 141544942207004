<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenUserNew" />
                    </template>
                </Toolbar>
                
                <DataTable ref="dtUser" 
                    :value="oListUser"
                    dataKey="UserId"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Usuario</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column field="idUser" header="Código" :sortable="true"></Column>
                    <Column field="firstName" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.firstName }}</b><br/>
                        </template>
                    </Column>
                    <Column field="login" header="Usuario"></Column>
                    <Column field="userType" header="Tipo"></Column>
                    <Column field="status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.Status ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.Status ? 'p-button-danger' : 'p-button-secondary'"  />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import UserService from '@/service/UsuarioService' ;

const router = useRouter();

const bCargando = ref(false);
const filtros = ref({});

const userService = new UserService();

const userModel = ref({});
const oListUser = ref([]);

// const oListUser = ref([
//     { UsuarioId : 1, Code: 'U0001', Name: 'José Ruiz', User: 'jruiz', TypeUser: 'Administrador', Status: true },
//     { UsuarioId : 2, Code: 'U0001', Name: 'Edgar Quiroz', User: 'equiroz', TypeUser: 'Administrador', Status: true },
//     { UsuarioId : 3, Code: 'U0001', Name: 'Hector Castro', User: 'hcastro', TypeUser: 'Administrador', Status: true }
// ]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    loadGridList();
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenUserNew = () => {
    userModel.value = {};
    router.push({ path: `/portal/security/usernew/${1}` });
}

const loadGridList = () => {
  userService.ListarUsuariosService().then(response => {
    oListUser.value = response;
  });
}
//#endregion
</script>