<template>
    <FichaAtencionSkeleton v-if="bCargando" />
    <div class="grid" v-else>
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <p><b class="ml-2">Ficha de Atención: </b> {{ nombrePaciente }} - {{ dniPaciente }}</p>                        
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
            </div>
            <div class="card pb-3">
                <div v-if="bCargando" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                            <div class="field col-12 md:col-9 pb-0 mb-0">
                                <p><b class="ml-2">Paquete: </b> {{ Producto.Nombre }} <Tag severity="info">{{ Producto.Categoria }}</Tag></p>
                            </div>
                            <div class="field col-12 md:col-3 text-right font-bold pb-0 mb-0">
                                <p v-if="bMostrarAtencion" >Atención #: {{ oFicha.CodigoFicha }}</p>
                            </div>

                            <Divider layout="horizontal" class="pt-1 mt-1 mb-5" />

                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oFicha.FechaInicio"
                                        inputId="dtpFechaInicio" dateFormat="dd/mm/yy" 
                                        :class="{ 'p-invalid' : fechaInicioValido }" :disabled="oListaCitas.length > 0 || oListaPagos.length > 0" />
                                    <label for="lblFechaInicio">Fecha Inicio</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oFicha.FechaFin"
                                        inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                        :class="{ 'p-invalid' : fechaFinValido }" />
                                    <label for="lblFechaFin">Fecha Fin</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Dropdown v-model="oFicha.EstadoFichaId" :options="oListaEstadoFicha"
                                        optionLabel="Nombre" optionValue="Codigo" class="w-full" :option-disabled="opcionDeshabilitada" />
                                    <label for="lblEstado">Estado</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="Producto.PrecioReal" disabled
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" />
                                    <label for="lblPrecio">Precio</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-2">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oFicha.DescuentoPorcentaje" @input="CalcularPrecioVentaPorcentaje" :min="0" :max="100"
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" :disabled="oListaCitas.length > 0 || oListaPagos.length > 0" />
                                    <label for="lblPrecioCompra">Descuento en %</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-2">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oFicha.Descuento" @input="CalcularPrecioVenta"
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" :disabled="oListaCitas.length > 0 || oListaPagos.length > 0" />
                                    <label for="lblPrecioCompra">Descuento en S/.</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oFicha.PrecioFinal" readonly
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" class="inputtext-total" />
                                    <label for="lblPrecioCompra">Precio Venta</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtDiagnostico" rows="3" cols="30" v-model="oFicha.Diagnostico" :disabled="oListaCitas.length > 0 || oListaPagos.length > 0" />
                                    <label for="lblDiagnostico">Diagnóstico</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-3 justify-content-end">
                        <Button label="Guardar" icon="pi pi-save" iconPos="right" @click="Guardar()" :loading="bCargando" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <Divider v-if="bFichaIniciada" layout="horizontal" />

    <div v-if="bFichaIniciada" class="card mb-3">
        <TabView class="tabview-custom">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar-plus"></i>
                        <span class="font-bold white-space-nowrap">Citas</span>
                    </div>
                </template>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevaCita" />
                    </template>
                </Toolbar>
                <div class="m-0">
                    <DataTable 
                        :value="oListaCitas" 
                        :rowHover="true"
                        :loading="bCargando"
                        class="p-datatable-sm"
                    >
                        <Column field="CodigoCitaAtencion" header="Código"></Column>
                        <Column field="NombrePersonal" header="Fisioterapeuta"></Column>
                        <Column field="FechaInicio" header="Fecha Inicio">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaInicio, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="FechaFin" header="Fecha Fin">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaFin, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="NombreEstado" header="Estado">
                            <template #body="slotProps">
                                <div class="centered-content">
                                    <Tag v-if="slotProps.data.EstadoCitaId == 3" :value="slotProps.data.NombreEstadoCita" severity="success" />
                                    <Tag v-if="slotProps.data.EstadoCitaId == 2" :value="slotProps.data.NombreEstadoCita" severity="info" />
                                    <Tag v-if="slotProps.data.EstadoCitaId == 1" :value="slotProps.data.NombreEstadoCita" severity="secondary" />
                                </div>
                            </template>
                        </Column>
                        <Column style="text-align: center;" headerStyle="min-width:12rem;">
                            <template #body="slotProps">
                                <Button icon="pi pi pi-pencil" v-tooltip.top="'Editar Cita'" class="p-button-rounded p-button-success mr-2"
                                    @click="EditarCita(slotProps.data.CitaAtencionId)" />
                                <Button icon="pi pi-times" v-tooltip.top="'Eliminar Cita'" class="p-button-rounded mt-2 p-button-danger"
                                    @click="EliminarCita(slotProps.data.CitaAtencionId, slotProps.data.CodigoCitaAtencion)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-apple"></i>
                        <span class="font-bold white-space-nowrap">Dietas</span>
                    </div>
                </template>
                <p class="m-0">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                    ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                </p>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-dollar"></i>
                        <span class="font-bold white-space-nowrap">Pagos</span>
                    </div>
                </template>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoPago" />
                    </template>
                    <template #end>
                        <i class="pi pi-info-circle" v-tooltip.top="`Solo podrá editar o eliminar un pago el misma caja que se realizó`"></i>
                    </template>
                </Toolbar>
                <p class="m-0">
                    <DataTable 
                        :value="oListaPagos" 
                        :rowHover="true"
                        :loading="bCargando"
                        class="p-datatable-sm"
                    >
                        <Column field="CodigoPagoAtencion" header="Código">
                            <template #body="slotProps">
                                <span v-tooltip.top="`Pagado Por:\n ${slotProps.data.NombrePagador}`">
                                    {{ slotProps.data.CodigoPagoAtencion }}
                                </span>
                            </template>
                        </Column>
                        <Column field="FechaPago" header="Fecha de Pago">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaPago, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="MedioPago" header="Medio de Pago">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.MedioPagoId > 1" v-tooltip.top="`Detalle Operación:\n ${slotProps.data.DetalleOperacion}`">
                                    {{ slotProps.data.MedioPago }}
                                    <p style="font-size: 8pt; color: grey;">
                                    Código Op.:  {{slotProps.data.CodigoOperacion}}
                                    </p>
                                </span>
                                <span v-if="slotProps.data.MedioPagoId == 1">
                                    {{ slotProps.data.MedioPago }}
                                </span>
                            </template>
                        </Column>
                        <Column field="Importe" header="Importe de Pago">
                            <template #body="slotProps">
                                <div class="field text-right font-bold">
                                    S/. {{ Utilitario.formatearDecimal(slotProps.data.Importe) }}
                                </div>
                            </template>
                        </Column>
                        <Column field="Observacion" header="Observación"></Column>
                        <Column style="text-align: center;" headerStyle="min-width:12rem;">
                            <template #body="slotProps">
                                <Button icon="pi pi-print" v-tooltip.top="'Ver ticket de Pago'" class="p-button-rounded p-button-secondary mr-2"
                                    @click="VerTicketPago(slotProps.data.PagoAtencionId)" />
                                <Button icon="pi pi-pencil" v-tooltip.top="'Editar Pago'" class="p-button-rounded p-button-success mr-2"
                                    @click="EditarPago(slotProps.data.PagoAtencionId)" :disabled="!slotProps.data.Editable" />
                                <Button icon="pi pi-times" v-tooltip.top="'Eliminar Pago'" class="p-button-rounded mt-2 p-button-danger"
                                    @click="EliminarPago(slotProps.data.PagoAtencionId, slotProps.data.CodigoPagoAtencion)" :disabled="!slotProps.data.Editable" />
                            </template>
                        </Column>
                    </DataTable>
                </p>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-history"></i>
                        <span class="font-bold white-space-nowrap">Historial</span>
                    </div>
                </template>
                <p class="m-0">
                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                    officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                </p>
            </TabPanel>
        </TabView>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import CitaAtencionService from '@/service/CitaAtencionService';
import ConstanteService from '@/service/ConstanteService';
import FichaAtencionService from '@/service/FichaAtencion';
import PacienteService from '@/service/PacienteService';
import PagoAtencionService from '@/service/PagoAtencion';
import ProductoService from '@/service/ProductoService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import FichaAtencionSkeleton from './FichaAtencionSkeleton.vue';

//#region Variables
const router = useRouter();
const route = useRoute();
const store = useStore();
const confirm = useConfirm();
const toast = useToast();

const pacienteService = new PacienteService();
const productoService = new ProductoService();
const constanteService = new ConstanteService();
const fichaAtencionService = new FichaAtencionService();
const citaAtencionService = new CitaAtencionService();
const pagoAtencionService = new PagoAtencionService();

const usuarioStore = JSON.parse(store.state.datauser);
const nombrePaciente = ref('');
const dniPaciente = ref('');
const oListaEstadoFicha = ref([]);
const oListaCitas = ref([]);
const oListaPagos = ref([]);
const Producto = ref({});
const oFicha = ref({
    Descuento: 0,
    DescuentoPorcentaje: 0
});
const bMostrarAtencion = ref(false);
const bCargando = ref(false);
const fichaId = ref('');
const pacienteId = ref('');
const personaId = ref('');
const fechaInicioValido = ref(false);
const fechaFinValido = ref(false);
const bFichaIniciada = ref(false);

// Propiedad para indicar el campo que se usa para deshabilitar opciones
const opcionDeshabilitada = 'disabled';
//#endregion

//#region Eventos
onMounted(() => {
     inicializador();
     CambiarEspanol();
});

//#endregion

//#region Metodos
const inicializador = async() => {
    bCargando.value = true;
    ListarEstadoFicha();
    ObtenerPaciente();
    ObtenerProducto();
    
    if(Utilitario.StringIsNullOrEmpty(fichaId.value)){
        oFicha.value.EstadoFichaId = 1;
        fichaId.value = Crypto.Desencriptar(route.params.id);
        pacienteId.value = Crypto.Desencriptar(route.params.pacienteid);
        if(!Utilitario.StringIsNullOrEmpty(fichaId.value)){
            await ObtenerFicha(fichaId.value);
            await ListarCitas();
            await ListarPagos();    
        }
    }
    else{
        await ObtenerFicha(fichaId.value);
        await ListarCitas();
        await ListarPagos();
    }
    bCargando.value = false;
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const IrAtras = () => {
    fichaId.value = Crypto.Desencriptar(route.params.id);
    if(Utilitario.StringIsNullOrEmpty(fichaId.value)){
        const idPaciente = route.params.pacienteid;
        router.push({ path: `/portal/fichaatencionseleccion/${encodeURIComponent(idPaciente)}` });
    }
    else{
        const idPersona = Crypto.Encriptar(personaId.value);
        router.push({ path: `/portal/nuevopaciente/${encodeURIComponent(idPersona)}` });
    }
}

const ListarEstadoFicha = () => {
    constanteService.ListarEstadoFichaAtencionService().then(
        response => {
            oListaEstadoFicha.value = response;
        }
    );
}

const ActualizarOpcionEstado = async() => {
    if(bFichaIniciada.value){
        oListaEstadoFicha.value = oListaEstadoFicha.value.map((option, index) => ({
            ...option,
            disabled: index === 0
        }));
    }
}

const ObtenerPaciente = () => {
    try{
        const idPaciente = Crypto.Desencriptar(route.params.pacienteid);
        pacienteService.ObtenerPacienteService(idPaciente).then(
            response => {
                if(response.Data){
                    nombrePaciente.value = response.Data.Persona.NombreCompleto;
                    dniPaciente.value = response.Data.Persona.NumeroDocumento;
                    personaId.value  = response.Data.Persona.PersonaId;
                }                
            }
        );
    }
    catch (error) {
        console.error('Error al ObtenerPaciente', error);
    }
}

const ObtenerProducto = async() => {
    const productoid = Crypto.Desencriptar(route.params.productoid);
    try{
        const response = await productoService.ObtenerProductoService(productoid).then();
        if(response.Status == 200 && (response.Data != null || response.Data != undefined)){
            const obtenerProducto = response.Data;
            Producto.value.ProductoId = obtenerProducto.ProductoId;
            Producto.value.CodigoProducto = obtenerProducto.Codigo;
            Producto.value.Nombre = obtenerProducto.Nombre;
            Producto.value.Descripcion = obtenerProducto.Descripcion;
            Producto.value.PrecioCompra = obtenerProducto.PrecioCompra;
            Producto.value.PrecioReal = obtenerProducto.PrecioVenta;
            Producto.value.Categoria = obtenerProducto.Categoria.Nombre;
            
            oFicha.value.PrecioFinal = obtenerProducto.PrecioVenta;
        }
    } catch (error) {
        console.error('Error al ObtenerProducto:', error);
    }
}

const ObtenerFicha = async(idParametro) => {
    try{
        const request = {
            Id: idParametro
        };

        const response = await fichaAtencionService.ObtenerFichaService(request).then();
        if(response.Status == 200){
            bMostrarAtencion.value = true;
            const obtenerFicha = response.Data;
            oFicha.value.FichaAtencionId = obtenerFicha.FichaAtencionId;
            oFicha.value.CodigoFicha = obtenerFicha.CodigoFichaAtencion;
            oFicha.value.PrecioReal = obtenerFicha.PrecioReal;
            oFicha.value.Descuento = obtenerFicha.Descuento;
            oFicha.value.DescuentoPorcentaje = obtenerFicha.DescuentoPorcentaje;
            oFicha.value.PrecioFinal = obtenerFicha.PrecioFinal;
            oFicha.value.FechaInicio = Utilitario.formatoDate(obtenerFicha.FechaInicio);
            oFicha.value.FechaFin = Utilitario.formatoDate(obtenerFicha.FechaFin);
            oFicha.value.Diagnostico = obtenerFicha.Diagnostico;
            oFicha.value.EstadoFichaId =obtenerFicha.EstadoFichaId;

            if(obtenerFicha.EstadoFichaId > 1){
                bFichaIniciada.value = true;
                await ActualizarOpcionEstado();
            }
        }
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
    }
}

const ListarCitas = async() => {
    try{
        const request = {
            FichaAtencionId: oFicha.value.FichaAtencionId
        };
        
        const response = await citaAtencionService.ListarCitasFichaService(request).then();
        if(response.Status == 200){
            oListaCitas.value = response.Data;
        }
    } catch (error) {
        console.error('Error al ListarCitas:', error);
    }
}

const CalcularPrecioVenta = (descuento) => {
    const precioVenta = parseFloat(Producto.value.PrecioReal);
    if(descuento.value == null || descuento.value > precioVenta){
        oFicha.value.DescuentoPorcentaje = 0;
        oFicha.value.Descuento = 0;
        oFicha.value.PrecioFinal = precioVenta;
        
        return;
    }

    const descuentoTotal = parseFloat(descuento.value);
    
    const descuentoPorcentaje = (descuentoTotal/precioVenta)*100;
    oFicha.value.DescuentoPorcentaje = Math.round(descuentoPorcentaje*100)/100;

    oFicha.value.Descuento = descuentoTotal;
    oFicha.value.PrecioFinal = precioVenta - descuentoTotal;
}

const CalcularPrecioVentaPorcentaje = (descuento) => {
    const precioVenta = parseFloat(Producto.value.PrecioReal);
    if(descuento.value == null || descuento.value > 100 || descuento.value < 0){
        oFicha.value.DescuentoPorcentaje = 0;
        oFicha.value.Descuento = 0;
        oFicha.value.PrecioFinal = precioVenta;

        return;
    }

    const descuentoPorcentaje = parseFloat(descuento.value);

    oFicha.value.DescuentoPorcentaje = descuentoPorcentaje;
    const descuentoTotal = (descuentoPorcentaje/100)*precioVenta;
    oFicha.value.Descuento = descuentoTotal;

    oFicha.value.PrecioFinal = precioVenta - descuentoTotal;
}

const Guardar = () => {
    const { ...model } = oFicha.value;
    const valida = ref(false);
    if(!model?.FechaInicio){
        fechaInicioValido.value = true;
        valida.value = true;
    }
    else{
        fechaInicioValido.value = false;
    }

    if(!model?.EstadoFichaId){
        fechaFinValido.value = true;
        valida.value = true;
    }
    else{
        if(model.EstadoFichaId == 3){
            if(!model?.FechaFin){
                fechaFinValido.value = true;
                valida.value = true;
            }
            else{
                fechaFinValido.value = false;
            }
        }
        else{
            fechaFinValido.value = false;
        }
    }

    if(valida.value){
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
        return;
    }
    
    const FichaAtencion = { 
        FichaAtencionId: fichaId.value || 0,
        PacienteId: pacienteId.value,
        ...Producto.value, 
        ...model,
        UsuarioCreacion: usuarioStore.User.UsuarioId
    }
    
    confirm.require({
            message: `¿Está seguro de guardar la ficha de atención?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargando.value = true;
                
                try{
                    const response = await fichaAtencionService.RegistrarFichaAtencionService(FichaAtencion);
                    if(response.Status == 201){
                        oFicha.value.CodigoFicha = response.Data[0].CodigoFicha;
                        fichaId.value = response.Data[0].FichaAtencionId;
                        bMostrarAtencion.value = true;

                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardó la ficha de atención #${oFicha.value.CodigoFicha} correctamente`,
                            accept: async() => {
                                bCargando.value = false;
                                router.push({ path: `/portal/fichaatencion/${encodeURIComponent(route.params.pacienteid)}/${encodeURIComponent(route.params.productoid)}/${encodeURIComponent(Crypto.Encriptar(fichaId.value))}` });
                                inicializador();
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar:`, error);
                }
            }
    });
}

const NuevaCita = () => {
    router.push({ path: `/portal/citaatencion/${encodeURIComponent(route.params.id)}` });
}

const EditarCita = (idCita) => {
    router.push({ path: `/portal/citaatencion/${encodeURIComponent(route.params.id)}/${encodeURIComponent(Crypto.Encriptar(idCita))}` });
}

const EliminarCita = (idCita, codigo) => {
    confirm.require({
        message: `¿Está seguro de eliminar la cita ${codigo}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Eliminar',
        acceptClass: 'p-button-danger',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    CitaAtencionId: idCita,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };

                const response = await citaAtencionService.EliminarCitaAtencionService(request).then();
                if(response.Status == 200){
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se eliminó la cita #${codigo} correctamente`,
                        accept: async() => {
                            await ListarCitas();
                            bCargando.value = false;
                        }
                    });                    
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al EliminarCita', error);
            }
        }
    });
}

const NuevoPago = () => {
    router.push({ path: `/portal/pagoatencion/${encodeURIComponent(route.params.id)}` });
}

const ListarPagos = async() => {
    try{
        const request = {
            FichaAtencionId: oFicha.value.FichaAtencionId,
            EstablecimientoId: usuarioStore.Establecimiento.EstablecimientoId
        };
        
        const response = await pagoAtencionService.ListarPagosAtencionService(request).then();
        if(response.Status == 200){
            oListaPagos.value = response.Data;
        }
    } catch (error) {
        console.error('Error al ListarPagos:', error);
    }
}

const EliminarPago = (idPago, codigo) => {
    confirm.require({
        message: `¿Está seguro de eliminar el pago #${codigo}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Eliminar',
        acceptClass: 'p-button-danger',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    PagoAtencionId: idPago,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };

                const response = await pagoAtencionService.EliminarPagoAtencionService(request).then();
                if(response.Status == 200){
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se eliminó el pago #${codigo} correctamente`,
                        accept: async() => {
                            await ListarPagos();
                            bCargando.value = false;
                        }
                    });                    
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al EliminarCita', error);
            }
        }
    });
}

const EditarPago = (idPago) => {
    router.push({ path: `/portal/pagoatencion/${encodeURIComponent(route.params.id)}/${encodeURIComponent(Crypto.Encriptar(idPago))}` });
}

const VerTicketPago = async(idPago) => {
    try{
        bCargando.value = true;
        const request = {
            PagoAtencionId: idPago,
            Formato : 'pdf',
            Usuario: usuarioStore.User.Usuario,
            EmpresaId: 1
        };
        
        const blob = await pagoAtencionService.VerPDFPagosAtencionService(request);
        const url = window.URL.createObjectURL(blob);
        bCargando.value = false;
        window.open(url);
    } catch (error) {
        console.error('Error al VerTicketPago:', error);
        bCargando.value = false;
    }
}

//#endregion
</script>