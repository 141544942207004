export default class CitaAtencionService {
    async RegistrarCitaAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/nuevacita', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarCitaAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/cita', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarCitasFichaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/citasficha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async EliminarCitaAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/eliminarcita', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async RegistrarCitaAtencionFotosService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/nuevacitafotos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarCitasFotosService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/citafotos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async EliminarCitaAtencionFotosService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/eliminarcitafotos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

}