<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <Button icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                            <div class="ml-3">
                                <h3 style="margin: 0;">David Ruiz <Tag value="Vigente" severity="success" /></h3>
                                <p style="margin: 0;">Cambios de revestimiento de molinos</p>
                            </div>
                        </div>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <TabView class="tabview-custom">
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-file-edit"></i>
                            <span class="font-bold white-space-nowrap">Selección</span>
                        </div>
                    </template>
                    <AccreditationPersonalSelection />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-book"></i>
                            <span class="font-bold white-space-nowrap">Gestión de cursos</span>
                        </div>
                    </template>
                    <AccreditationCourses />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-heart"></i>
                            <span class="font-bold white-space-nowrap">Gestión de EMOs</span>
                        </div>
                    </template>
                    <AccreditationEmos />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-bolt"></i>
                            <span class="font-bold white-space-nowrap">Contratación</span>
                        </div>
                    </template>
                    <AccreditationHiring />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-car"></i>
                            <span class="font-bold white-space-nowrap">Itinerario y viáticos</span>
                        </div>
                    </template>
                    <AccreditationItinerary />
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import AccreditationPersonalSelection from './Personal/AccreditationPersonalSelection.vue';
import AccreditationCourses from './Courses/AccreditationCourses.vue';
import AccreditationEmos from './Emos/AccreditationEmos.vue';
import AccreditationHiring from './Hiring/AccreditationHiring.vue';
import Crypto from '@/utilitarios/Crypto';
import AccreditationItinerary from './Itinerary/AccreditationItinerary.vue';

const router = useRouter();

const filtros = ref({});
const oListPersonal = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    const servideId = encodeURIComponent(Crypto.Encriptar(1));
    router.push({ path: `/portal/Servicio/Detalle/${servideId}` });
}

const LoadPersonal = () => {
    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            Status: 'Disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false,
            DocumentsPersonal: [
                {
                    Name: 'CV 2024 - 0.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 0.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 0.pdf',
                    Status: false
                }
            ],            
            DocumentsCourses: [
                {
                    Name: 'CV 2024 - 00.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 00.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 00.pdf',
                    Status: false
                }
            ],
            DocumentsEmos: [
                {
                    Name: 'CV 2024.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven.pdf',
                    Status: false
                }
            ]
        }
    ];
}

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
