<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <Button icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                            <div class="ml-3">
                                <h3 style="margin: 0;">Cambios de revestimiento de molinos</h3>
                                <p style="margin: 0;">Última modificación: 10/05/2024</p>
                            </div>
                        </div>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <TabView>
                    <TabPanel header="ACREDITACIÓN">
                        <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
                            <div class="grid">
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <!--<span class="block font-semibold">Cumplimiento</span>-->
                                                <div class="text-900 font-bold text-2xl"><b>40%</b></div>
                                                <div class="font-medium text-sm">4 de 10 requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-file text-green-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>10</b></div>
                                                <div class="font-medium text-sm">Requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user text-cyan-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>1</b></div>
                                                <div class="font-medium text-sm">Acreditado</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>4</b></div>
                                                <div class="font-medium text-sm">Coincidencias</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>               
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 xl:col-12 m-0 p-0">
                            <div class="card">
                                <DataTable ref="dtPersonal" 
                                    :value="oListPersonal"
                                    dataKey="PersonId"
                                    :rowHover="true"
                                    :loading="bCargando"
                                    :filters="filtros"
                                    class="p-datatable-sm"
                                    >

                                    <template #header>
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <h5 class="m-0">Personal seleccionado</h5>
                                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                                <Button label="Agregar" icon="pi pi-plus" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                                @click="modalSearchPersonal = true" />
                                            </span>
                                        </div>
                                    </template>

                                    <Column style="text-align: center;" headerStyle="min-width:5rem;">
                                        <template #body="slotProps">
                                            <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                                        @click="ViewAccreditationPersonal(slotProps.data)"/>
                                        </template>
                                    </Column>
                                    <Column field="Index" header="#"></Column>
                                    <Column field="DocumentNumber" header="DNI" >
                                        <template #body="slotProps">
                                            <span v-tooltip.top="`Fecha Vencimiento:\n${ slotProps.data.ExpirationDate }`">{{ slotProps.data.DocumentNumber }}</span>
                                        </template>
                                    </Column>
                                    <Column field="FullName" header="Nombre y Apellido">
                                        <template #body="slotProps">
                                            <Button :label="slotProps.data.FullName" link @click="ViewAccreditationPersonal(slotProps.data)" />
                                        </template>
                                    </Column>
                                    <Column field="TypeEmployee" header="Tipo Contrato"></Column>
                                    <Column field="MinCondition" header="Tipo Ingreso"></Column>
                                    <Column field="DocumentNumber" header="Doc. Pers." bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.PersonalDocument, 'pi-times-circle text-red-500': !slotProps.data.PersonalDocument }"
                                            aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsPersonal)"></i>
                                        </template>
                                    </Column>
                                    <Column field="DocumentNumber" header="Cursos" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Courses, 'pi-times-circle text-red-500': !slotProps.data.Courses }"
                                            aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsCourses)"></i>
                                        </template>
                                    </Column>
                                    <Column field="Status" header="EMOs" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Emos, 'pi-times-circle text-red-500': !slotProps.data.Emos }"
                                            aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsEmos)"></i>
                                        </template>
                                    </Column>
                                    <Column field="Status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <div class="centered-content">
                                                <Tag v-if="slotProps.data.Status=='Disponible'" value="Disponible" severity="success" />
                                                <Tag v-if="slotProps.data.Status=='Acreditado'" value="Acreditado" severity="info" />
                                                <Tag v-if="slotProps.data.Status=='No disponible'" value="No disponible" severity="secondary" />
                                                <Tag v-if="slotProps.data.Status=='Vigente'" value="Vigente" severity="warning" />
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            
                            <OverlayPanel ref="op" appendTo="body">
                                <DataTable :value="documents" selectionMode="single" >
                                    <Column field="Name" header="Documento" style="min-width: 12rem"></Column>
                                    <Column field="Status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Status, 'pi-times-circle text-red-500': !slotProps.data.Status }"></i>
                                        </template>
                                    </Column>
                                </DataTable>
                            </OverlayPanel>

                        </div>
                    </TabPanel>
                    <TabPanel header="GASTOS DE MOVILIZACIÓN">
                        <AccreditationCoasts />
                    </TabPanel>
                </TabView>
            </div>
        </div>
        
        <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar personal" >
            <SearchPersonal @closeModal="closeModal" @sendList="AddPersonal" /> 
        </Dialog>
    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import SearchPersonal from '../Personal/SearchPersonal.vue';
import AccreditationCoasts from '../Accreditation/AccreditationCoasts.vue';

const router = useRouter();

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListPersonalSelected = ref([]);

const op = ref();
const documents = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    router.push({ path: `/portal` });
}

const closeModal = () => {
  modalSearchPersonal.value = false;
}

const LoadPersonal = () => {
    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            Status: 'Disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false,
            DocumentsPersonal: [
                {
                    Name: 'CV 2024 - 0.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 0.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 0.pdf',
                    Status: true
                }
            ],            
            DocumentsCourses: [
                {
                    Name: 'CV 2024 - 00.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 00.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 00.pdf',
                    Status: true
                }
            ],
            DocumentsEmos: [
                {
                    Name: 'CV 2024.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven.pdf',
                    Status: false
                }
            ]
        },
        {
            Index: 2,
            PersonId: 3,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Acreditado',
            PersonalDocument: true,
            Courses: true,
            Emos: false,
            DocumentsPersonal: [
                {
                    Name: 'CV 2024 - 10.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 10.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 10.pdf',
                    Status: true
                }
            ],            
            DocumentsCourses: [
                {
                    Name: 'CV 2024 - 100.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 100.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 100.pdf',
                    Status: true
                }
            ],
            DocumentsEmos: [
                {
                    Name: 'CV 2024 20.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja 20.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven 20.pdf',
                    Status: false
                }
            ]
        },
        {
            Index: 3,
            PersonId: 3,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'No disponible',
            PersonalDocument: false,
            Courses: false,
            Emos: false
        },
        {
            Index: 4,
            PersonId: 3,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Vigente',
            PersonalDocument: false,
            Courses: false,
            Emos: false
        }
    ];
}

const toggle = (event, olista) => {
    documents.value = olista;
    op.value.toggle(event);
};

const AddPersonal = (list) => {
    console.log(list.value);
  oListPersonalSelected.value = list.value; // Asigna la lista recibida a la variable reactiva
};

const ViewAccreditationPersonal = () => {
    router.push({ path: `/portal/Accreditation/Personal` });
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
