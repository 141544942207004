<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                        <div class="field col-12 md:col-12 mb-1 pb-0">
                            <h5>Reporte de Pagos</h5>
                        </div>
                        <div class="field col-12 md:col-3 mb-0">
                            <FloatLabel>
                                <Dropdown :options="oListaMediosPago" v-model="oReportePago.MedioPagoId"
                                    optionLabel="Nombre" optionValue="MedioPagoId" class="w-full" @change="LimpiarReporte()" />
                                <label for="lblMedioPago">Medio de Pago</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2 mb-0">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" v-model="oReportePago.FechaInicio"
                                    inputId="dtpFechaPago" dateFormat="dd/mm/yy" hourFormat="12"
                                    :class="{ 'p-invalid' : fechaInicioInvalido }" @input="LimpiarReporte()" />
                                <label for="lblFechaPago">Fecha Inicio</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2 mb-0">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" v-model="oReportePago.FechaFin"
                                    inputId="dtpFechaPago" dateFormat="dd/mm/yy" hourFormat="12"
                                    :class="{ 'p-invalid' : fechaFinInvalido }" @change="LimpiarReporte()" />
                                <label for="lblFechaPago">Fecha Fin</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2 mb-0">
                            <Button label="Buscar" icon="pi pi-search" class="p-button-success mr-2" @click="ListarReporte()" />
                        </div>
                        <Divider layout="vertical" />
                        <div class="field col-12 md:col-2 mb-0">
                            <Button icon="pi pi-file-excel" class="mr-2" severity="secondary" v-tooltip.top="'Descargar Excel'" @click="GenerarReporteExcel()" />
                            <Button icon="pi pi-file-pdf" class="mr-2" severity="secondary" v-tooltip="'Descargar PDF'" @click="GenerarReportePDF()" />
                        </div>

                    </div>
                </div>
            </div>

            <div class="card p-2 mb-2" v-if="bSinResultado">
                <br>
                <p class="text-center">No hay resultado para el reporte de {{ Utilitario.formatoFecha(oReportePago.FechaInicio, 'dd/MM/yyyy') }} - {{ Utilitario.formatoFecha(oReportePago.FechaFin, 'dd/MM/yyyy') }}</p>
                <br>
            </div>
            <div class="card p-2 mb-2" v-if="oListaReportePagos.length > 0">
                <DataTable ref="dtReporte" 
                    :value="oListaReportePagos"
                    dataKey="PagoAtencionId"
                    :rowHover="true"
                    :paginator="true" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Reporte de {{ Utilitario.formatoFecha(oReportePago.FechaInicio, 'dd/MM/yyyy') }} - {{ Utilitario.formatoFecha(oReportePago.FechaFin, 'dd/MM/yyyy') }}</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column field="CodigoPagoAtencion" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span v-tooltip.top="`Código Caja:\n${slotProps.data.CodigoCaja}`">
                                {{ slotProps.data.CodigoPagoAtencion }}
                            </span>
                        </template>
                    </Column>
                    <Column field="MedioPago" header="Medio Pago" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.MedioPago }}</b>
                        </template>
                    </Column>
                    <Column field="FechaPago" header="Fecha de Pago">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.FechaPago, "dd/MM/yyyy HH:mm:ss") }}
                        </template>
                    </Column>
                    <Column field="Documento" header="Documento">
                        <template #body="slotProps">
                            {{ slotProps.data.Documento }}
                        </template>
                    </Column>
                    <Column field="NombrePagador" header="Paciente">
                        <template #body="slotProps">
                            {{ slotProps.data.NombrePagador }}
                        </template>
                    </Column>
                    <Column field="Paquete" header="Producto/Servicio" :sortable="true">
                        <template #body="slotProps">
                            <Button :label="slotProps.data.Paquete" link @click="IrFicha(slotProps.data)" v-tooltip.top="slotProps.data.TipoProducto" />
                        </template>
                    </Column>
                    <Column field="Observacion" header="Observacion" :sortable="true"></Column>
                    <Column field="Importe" header="Importe" :sortable="true">
                        <template #body="slotProps">
                            <div class="field text-right font-bold">
                                S/. {{ Utilitario.formatearDecimal(slotProps.data.Importe) }}
                            </div>
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>
</template>


<script setup>
import MedioPagoService from '@/service/MedioPagoService';
import ReporteService from '@/service/ReporteService';
import store from '@/store';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

//#region Variables
const confirm = useConfirm();
const toast = useToast();
const router = useRouter();

const medioPagoServicio = new MedioPagoService();
const reporteServicio = new ReporteService();

let usuarioStore = JSON.parse(store.state.datauser);
const bCargando = ref(false);
const filtros = ref({});
const oListaMediosPago = ref([]);
const oReportePago = ref({
    MedioPagoId: 0,
    FechaInicio: new Date,
    FechaFin: new Date
});
const fechaInicioInvalido = ref(false);
const fechaFinInvalido = ref(false);
const oListaReportePagos = ref([]);
const bSinResultado = ref(false);
//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
    CambiarEspanol();
});

onBeforeMount(() => {
    inicializarFiltros();
});
//#endregion


//#region Metodos
const Inicializador = () => {
    bCargando.value= true;
    ListarMedioPago();
}

const inicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.pending = "Pendiente";
}

const ListarMedioPago = async() => {
    const response = await medioPagoServicio.ListarMedioPagoService().then();
    if(response.Status == 200) {
        const listaMedios = response.Data;
        const opcionTodos = { MedioPagoId: 0, Nombre: '-- Todos --' };
        
        oListaMediosPago.value = [opcionTodos, ...listaMedios];
    }
}

const ListarReporte = async() => {
    try{
        LimpiarReporte();

        let estadoValidacion = false;
        const {...model} = oReportePago.value;
        
        if(!model?.FechaInicio){
            fechaInicioInvalido.value = true;
            estadoValidacion = true;
        }
        else{
            fechaInicioInvalido.value = false;
        }

        if(!model?.FechaFin){
            fechaFinInvalido.value = true;
            estadoValidacion = true;
        }
        else{
            fechaFinInvalido.value = false;
        }

        if(estadoValidacion){
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
            return;
        }

        const request = {
            MedioPagoId: oReportePago.value.MedioPagoId,
            FechaInicio: Utilitario.formatoFecha(oReportePago.value.FechaInicio, 'yyyy-MM-dd') + ' 00:00:00',
            FechaFin: Utilitario.formatoFecha(oReportePago.value.FechaFin, 'yyyy-MM-dd') + ' 23:59:59',
            EstablecimientoId : usuarioStore.User.UsuarioId
        }
        
        bCargando.value = true;
        const response = await reporteServicio.ReportarPagos(request).then();
        if(response.Status == 200 && response.Data.length > 0){
            bSinResultado.value = false;
            oListaReportePagos.value = response.Data;
        }else {
            bSinResultado.value = true;
        }
        bCargando.value = false;
    } catch (error) {
        bCargando.value = false;
        bSinResultado.value = true;
        console.error('Error al ObtenerFicha:', error);
    }
}

const IrFicha = async(producto) => {
    const pacienteIdUrl = encodeURIComponent(Crypto.Encriptar(producto.PacienteId));
    const productoIdUrl = encodeURIComponent(Crypto.Encriptar(producto.ProductoId));
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(producto.FichaAtencionId));
    
    let route = router.resolve({ path: `/portal/fichaatencion/${pacienteIdUrl}/${productoIdUrl}/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}

const LimpiarReporte = async() => {
    oListaReportePagos.value = [];
}

const GenerarReportePDF = async() => {
    try{
        bCargando.value = true;
        
        if(oListaReportePagos.value.length <= 0){
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
            return;
        }
        
        const request = {
            NombreArchivo: `REPORTE_PAGOS_${Utilitario.fechaHoraTexto()}`,
            TituloReporte: "REPORTE DE PAGOS",
            DescripcionReporte : `DEL ${Utilitario.formatoFecha(oReportePago.value.FechaInicio, 'dd/MM/yyyy')} AL ${Utilitario.formatoFecha(oReportePago.value.FechaFin, 'dd/MM/yyyy')}`,
            Formato : "A4",
            Orientacion : "H",
            Usuario : usuarioStore.User.Usuario,
            EmpresaId : usuarioStore.Establecimiento.EmpresaId,
            Procedimiento: "REPORTE_PAGOS_LISTAR",
            Parametros: [
                {
                    Parametro: "EstablecimientoId",
                    Valor: "1",
                    TipoDato: "Int"
                },
                {
                    Parametro: "MedioPagoId",
                    Valor: oReportePago.value.MedioPagoId,
                    TipoDato: "Int"
                },
                {
                    Parametro: "FechaInicio",
                    Valor: Utilitario.formatoFecha(oReportePago.value.FechaInicio, 'yyyy-MM-dd') + ' 00:00:00',
                    TipoDato: "Datetime"
                },
                {
                    Parametro: "FechaFin",
                    Valor: Utilitario.formatoFecha(oReportePago.value.FechaFin, 'yyyy-MM-dd') + ' 23:59:59',
                    TipoDato: "Datetime"
                }
            ],
            Columnas: [
                {
                    property: "CodigoPagoAtencion", label : "Código", tipoDato: "string",
                    width: 55, headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "MedioPago", label: "Medio de Pago", width: 100, tipoDato: "string",
                    headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "FechaPago", label: "Fecha", width: 90, tipoDato: "datetime"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "Documento", label: "Documento", width: 70, tipoDato: "string"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "NombrePagador", label : "Paciente", width: 130, tipoDato: "string"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "Paquete", label: "Paquete", width: 140, tipoDato: "string"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "Observacion", label: "Observacion", width: 120, tipoDato: "string"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                },
                {
                    property: "Importe", label: "Importe", width: 60, tipoDato: "float"
                    , headerColor: "green", valign: "center", headerAlign:"center", align: "center"
                }
            ]
        };
        
        const blob = await reporteServicio.DescargarReportePDFService(request);
        const url = window.URL.createObjectURL(blob);
        bCargando.value = false;
        window.open(url);
    } catch (error) {
        console.error('Error al VerTicketPago:', error);
        bCargando.value = false;
    }
}

const GenerarReporteExcel = async() => {
    try{
        bCargando.value = true;

        if(oListaReportePagos.value.length <= 0){
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
            return;
        }
        
        const request = {
            NombreArchivo: `REPORTE_PAGOS_${Utilitario.fechaHoraTexto()}`,
            TituloReporte: "REPORTE DE PAGOS",
            DescripcionReporte : `DEL ${Utilitario.formatoFecha(oReportePago.value.FechaInicio, 'dd/MM/yyyy')} AL ${Utilitario.formatoFecha(oReportePago.value.FechaFin, 'dd/MM/yyyy')}`,
            Usuario : usuarioStore.User.Usuario,
            EmpresaId : usuarioStore.Establecimiento.EmpresaId,
            Procedimiento: "REPORTE_PAGOS_LISTAR",
            Parametros: [
                {
                    Parametro: "EstablecimientoId",
                    Valor: "1",
                    TipoDato: "Int"
                },
                {
                    Parametro: "MedioPagoId",
                    Valor: oReportePago.value.MedioPagoId,
                    TipoDato: "Int"
                },
                {
                    Parametro: "FechaInicio",
                    Valor: Utilitario.formatoFecha(oReportePago.value.FechaInicio, 'yyyy-MM-dd') + ' 00:00:00',
                    TipoDato: "Datetime"
                },
                {
                    Parametro: "FechaFin",
                    Valor: Utilitario.formatoFecha(oReportePago.value.FechaFin, 'yyyy-MM-dd') + ' 23:59:59',
                    TipoDato: "Datetime"
                }
            ],
            Columnas: [
                {
                    property: "CodigoPagoAtencion", label : "Código", width : 15, headerColor: "EBF1DE"
                },
                {
                    property: "MedioPago", label : "Medio de Pago", width : 15, headerColor: "EBF1DE"
                },
                {
                    property: "FechaPago", label : "Fecha", width : 15, headerColor: "EBF1DE"
                },
                {
                    property: "Documento", "label" : "Documento", "width" : 15, "headerColor": "EBF1DE"
                },
                {
                    property: "NombrePagador", label : "Paciente", width : 20, headerColor: "EBF1DE"
                },
                {
                    property: "Paquete", label : "Paquete", width : 20, headerColor: "EBF1DE"
                },
                {
                    property: "Observacion", label : "Observacion", width : 20, headerColor: "EBF1DE"
                },
                {
                    property: "Importe", label : "Importe", width: 15, headerColor: "EBF1DE"
                }
            ]
        };
        
        const blob = await reporteServicio.DescargarReporteExcelService(request);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${request.NombreArchivo}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        bCargando.value = false;
    } catch (error) {
        console.error('Error al VerTicketPago:', error);
        bCargando.value = false;
    }
}

watch(() => oReportePago.value.FechaInicio, () => {
    LimpiarReporte();
});

watch(() => oReportePago.value.FechaFin, () => {
    LimpiarReporte();
});
//#endregion
</script>