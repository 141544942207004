<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Datos personales</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Editar datos personales`" class="p-button-success ml-2" 
                                @click="modalPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListPersonalData"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="DNI" header="DNI" >
                        <template #body="slotProps">
                            <b v-tooltip.top="`Fecha Vencimiento:\n${ slotProps.data.ExpirationDateDni }`">{{ slotProps.data.DNI }}</b>
                        </template>
                    </Column>
                    <Column field="OriginCity" header="Ciudad origen"></Column>
                    <Column field="Mobile" header="Celular"></Column>
                    <Column field="ExpirationDatePassport" header="Pasaporte">
                        <template #body="slotProps">
                            <span v-tooltip.top="`Pasaporte:\n${ slotProps.data.Passport }`">{{ slotProps.data.ExpirationDatePassport }}</span>
                        </template>
                    </Column>
                    <Column field="Visa" header="VISA">
                        <template #body="slotProps">
                            <span v-tooltip.top="`Visa:\n${ slotProps.data.Visa }`">{{ slotProps.data.ExpirationDatePassport }}</span>
                        </template>
                    </Column>
                    <Column field="CertiAdult" header="Certiadulto/Certijoven">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.CertiAdult" severity="success" />
                            </div>
                        </template>
                    </Column>
                    <Column field="Background" header="Antecedentes">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.Background" severity="success" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Record de servicios</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListProjectRecord"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Mining" header="Empresa"></Column>
                    <Column field="Project" header="Proyecto"></Column>
                    <Column field="PeriodMonth" header="Mes período"></Column>
                    <Column field="PeriodYear" header="Año período"></Column>
                    <Column field="PorcProject" header="% participación servicios"></Column>
                    <Column field="PartProjectParal" header="% participación servicios paralelo"></Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">EPPs</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListEPP"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="ShirtSize" header="Talla Camisa"></Column>
                    <Column field="PantsSize" header="Talla Pantalon"></Column>
                    <Column field="ShoesSize" header="Zapatos"></Column>
                    <Column field="PhotocheckProcess" header="Trámite fotocheck"></Column>
                    <Column field="Photocheck" header="N° fotocheck"></Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Información salarial y bancaria</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                
                <DataTable ref="dtPersonal" 
                    :value="oListSalary"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column field="ValidationScale" header="Validación escala"></Column>
                    <Column field="Concept" header="Concepto"></Column>
                    <Column field="Days" header="Días"></Column>
                    <Column field="Amount" header="Monto"></Column>
                    <Column field="CenterCost" header="Centro costos"></Column>
                </DataTable>
                <br />
                <DataTable ref="dtPersonal" 
                    :value="oListBank"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Currency" header="Moneda" ></Column>
                    <Column field="SalaryAccount" header="Cuenta sueldo" ></Column>
                    <Column field="CciAccount" header="Cuenta Interbancaria" ></Column>
                    <Column field="EntityBank" header="Entidad bancaría"></Column>
                    <Column field="StatusBank" header="Entidad bancaría"></Column>
                </DataTable>
            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" />
        </div>
    </div>

    
    <Dialog v-model:visible="modalPersonal" modal header="Editar datos" :style="{ width: '40rem' }">
        <AccreditationPersonalEdit @closeModal="closeModalPersonal" /> 
    </Dialog>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import AccreditationPersonalEdit from './AccreditationPersonalEdit.vue';

const router = useRouter();

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListDocumentsPersonal = ref([]);
const oListPersonalData = ref([]);
const oListProjectRecord = ref([]);
const oListEPP = ref([]);
const oListSalary = ref([]);
const oListBank = ref([]);

const modalPersonal = ref(false);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {
    oListPersonalData.value = [{
        DNI: "73035743",
        ExpirationDateDni: '29/02024',
        OriginCity: 'Chimbote',
        Mobile: '95855562',
        Passport: '200002522633547',
        ExpirationDatePassport: '15/03/2024',
        Visa: '875455252522366',
        ExpirationDateVisa: '29/02024',
        SituationVisa: 'String',
        CertiAdult: 'Vigente',
        Background: 'Vigente',
        Status: 'Vigente'
    }];

    oListProjectRecord.value = [{
        QuantityProject: 12,
        Mining: 'Hudbay',
        Project: 'Mecanico 10',
        PeriodMonth: 'Noviembre',
        PeriodYear: 2024,
        Position: 'Mecánico 3',
        PorcProject: 24,
        PartProjectParal: 'LUNDIN'
    },
    {
        QuantityProject: 12,
        Mining: 'Yanacocha',
        Project: 'Soldador 1',
        PeriodMonth: 'Noviembre',
        PeriodYear: 2023,
        Position: 'Mecánico 3',
        PorcProject: 24,
        PartProjectParal: 'LUNDIN'
    }];

    oListEPP.value = [{
        ShirtSize: 'M',
        PantsSize: '30',
        ShoesSize: 43,
        PhotocheckProcess: 'Nuevo',
        Photocheck: '00001',
    }];

    oListSalary.value = [{
        ValidationScale: 'Si',
        Concept: 'Parada',
        Days: 4,
        Amount: 8000,
        CenterCost: 'Definir'
    }];

    oListBank.value = [{
        Currency: 'Soles',
        SalaryAccount: '022 **** **** ****',
        CciAccount: '024-0**-***-*****-*-***',
        EntityBank: 'BCP',
        StatusBank: 'Activo'
    }];

    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            Status: 'Disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false,
            DocumentsPersonal: [
                {
                    Name: 'CV 2024 - 0.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 0.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 0.pdf',
                    Status: false
                }
            ],            
            DocumentsCourses: [
                {
                    Name: 'CV 2024 - 00.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja - 00.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven - 00.pdf',
                    Status: false
                }
            ],
            DocumentsEmos: [
                {
                    Name: 'CV 2024.pdf',
                    Status: true
                },
                {
                    Name: 'Hoja.pdf',
                    Status: true
                },
                {
                    Name: 'CertiJoven.pdf',
                    Status: false
                }
            ]
        }
    ];

    oListDocumentsPersonal.value = [
        {
            Index: 1,
            Document: 'DNI',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FDNI.pdf?alt=media&token=39517142-aa67-4f36-a1a2-190b4f3c4bfb',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 2,
            Document: 'CV',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FHoja%20de%20Vida.pdf?alt=media&token=734674ea-826f-4183-844a-6f2f3805593c',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 3,
            Document: 'FORSEC',
            Url: '',
            LastDate: '01/12/2024',
            Status: false
        },
        {
            Index: 4,
            Document: 'PASAPORTE',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 5,
            Document: 'VISA',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 6,
            Document: 'CERTIADULTO/CERTIJOVEN',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 7,
            Document: 'ANTECEDENTES',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        }
    ];
}

const closeModalPersonal = () => {
  modalPersonal.value = false;
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
