<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <Skeleton width="200px" height="1.5rem" class="ml-2" />
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary"></Button>
                    </template>
                </Toolbar>
            </div>
            
            <div class="card pb-3">
                <div class="flex flex-column h-500rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                            <div class="field col-12 md:col-9 pb-0 mb-0">
                                <Skeleton width="200px" height="1.5rem" class="ml-2" />
                            </div>
                            <div class="field col-12 md:col-3 text-right font-bold pb-0 mb-0">                                
                                <Skeleton width="200px" height="1.5rem" class="ml-2" />
                            </div>

                            <Divider layout="horizontal" class="pt-1 mt-1 mb-5" />

                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-2">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-2">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-12">
                                <Skeleton width="100%" height="4rem" />
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-3 justify-content-end">
                        <div class="field col-12 md:col-10"></div>
                        <div class="field col-12 md:col-2">
                            <Skeleton width="100%" height="2rem" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
<!--
    <Divider v-if="bFichaIniciada" layout="horizontal" />

    <div v-if="bFichaIniciada" class="card mb-3">
        <TabView class="tabview-custom">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar-plus"></i>
                        <span class="font-bold white-space-nowrap">Citas</span>
                    </div>
                </template>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevaCita" />
                    </template>
                </Toolbar>
                <div class="m-0">
                    <DataTable 
                        :value="oListaCitas" 
                        :rowHover="true"
                        :loading="bCargando"
                        class="p-datatable-sm"
                    >
                        <Column field="CodigoCitaAtencion" header="Código"></Column>
                        <Column field="NombrePersonal" header="Fisioterapeuta"></Column>
                        <Column field="FechaInicio" header="Fecha Inicio">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaInicio, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="FechaFin" header="Fecha Fin">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaFin, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="NombreEstado" header="Estado">
                            <template #body="slotProps">
                                <div class="centered-content">
                                    <Tag v-if="slotProps.data.EstadoCitaId == 3" :value="slotProps.data.NombreEstadoCita" severity="success" />
                                    <Tag v-if="slotProps.data.EstadoCitaId == 2" :value="slotProps.data.NombreEstadoCita" severity="info" />
                                    <Tag v-if="slotProps.data.EstadoCitaId == 1" :value="slotProps.data.NombreEstadoCita" severity="secondary" />
                                </div>
                            </template>
                        </Column>
                        <Column style="text-align: center;" headerStyle="min-width:12rem;">
                            <template #body="slotProps">
                                <Button icon="pi pi pi-pencil" v-tooltip.top="'Editar Cita'" class="p-button-rounded p-button-success mr-2"
                                    @click="EditarCita(slotProps.data.CitaAtencionId)" />
                                <Button icon="pi pi-times" v-tooltip.top="'Eliminar Cita'" class="p-button-rounded mt-2 p-button-danger"
                                    @click="EliminarCita(slotProps.data.CitaAtencionId, slotProps.data.CodigoCitaAtencion)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-apple"></i>
                        <span class="font-bold white-space-nowrap">Dietas</span>
                    </div>
                </template>
                <p class="m-0">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                    ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                </p>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-dollar"></i>
                        <span class="font-bold white-space-nowrap">Pagos</span>
                    </div>
                </template>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoPago" />
                    </template>
                    <template #end>
                        <i class="pi pi-info-circle" v-tooltip.top="`Solo podrá editar o eliminar un pago el misma caja que se realizó`"></i>
                    </template>
                </Toolbar>
                <p class="m-0">
                    <DataTable 
                        :value="oListaPagos" 
                        :rowHover="true"
                        :loading="bCargando"
                        class="p-datatable-sm"
                    >
                        <Column field="CodigoPagoAtencion" header="Código">
                            <template #body="slotProps">
                                <span v-tooltip.top="`Pagado Por:\n ${slotProps.data.NombrePagador}`">
                                    {{ slotProps.data.CodigoPagoAtencion }}
                                </span>
                            </template>
                        </Column>
                        <Column field="FechaPago" header="Fecha de Pago">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.FechaPago, "dd/MM/yyyy HH:mm:ss") }}
                            </template>
                        </Column>
                        <Column field="MedioPago" header="Medio de Pago">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.MedioPagoId > 1" v-tooltip.top="`Detalle Operación:\n ${slotProps.data.DetalleOperacion}`">
                                    {{ slotProps.data.MedioPago }}
                                    <p style="font-size: 8pt; color: grey;">
                                    Código Op.:  {{slotProps.data.CodigoOperacion}}
                                    </p>
                                </span>
                                <span v-if="slotProps.data.MedioPagoId == 1">
                                    {{ slotProps.data.MedioPago }}
                                </span>
                            </template>
                        </Column>
                        <Column field="Importe" header="Importe de Pago">
                            <template #body="slotProps">
                                <div class="field text-right font-bold">
                                    S/. {{ Utilitario.formatearDecimal(slotProps.data.Importe) }}
                                </div>
                            </template>
                        </Column>
                        <Column field="Observacion" header="Observación"></Column>
                        <Column style="text-align: center;" headerStyle="min-width:12rem;">
                            <template #body="slotProps">
                                <Button icon="pi pi-print" v-tooltip.top="'Ver ticket de Pago'" class="p-button-rounded p-button-secondary mr-2"
                                    @click="VerTicketPago(slotProps.data.PagoAtencionId)" />
                                <Button icon="pi pi-pencil" v-tooltip.top="'Editar Pago'" class="p-button-rounded p-button-success mr-2"
                                    @click="EditarPago(slotProps.data.PagoAtencionId)" :disabled="!slotProps.data.Editable" />
                                <Button icon="pi pi-times" v-tooltip.top="'Eliminar Pago'" class="p-button-rounded mt-2 p-button-danger"
                                    @click="EliminarPago(slotProps.data.PagoAtencionId, slotProps.data.CodigoPagoAtencion)" :disabled="!slotProps.data.Editable" />
                            </template>
                        </Column>
                    </DataTable>
                </p>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-history"></i>
                        <span class="font-bold white-space-nowrap">Historial</span>
                    </div>
                </template>
                <p class="m-0">
                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                    officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                </p>
            </TabPanel>
        </TabView>
    </div>
-->
</template>
