<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nueva Empresa</h1>
                    </template>

                    <template #end>
                        
                    </template>
                </Toolbar>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListCountry" 
                                    optionLabel="Name" optionValue="CountryId" 
                                    placeholder="País" class="w-full"  />
                                <label for="lblUnidadMedida">País</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtProductoId" type="hidden" />
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Código</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">R.U.C.</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Razón Social</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>             
                        <div class="field col-12 md:col-8 pb-0">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Dirección</label>
                            </FloatLabel>
                        </div>
                        <Divider layout="horizontal" class="p-0 field col-12 md:col-8" />
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" />
                                <label for="lblCodigoProducto">Sponsor 1</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" />
                                <label for="lblCodigoProducto">Cargo</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" />
                                <label for="lblCodigoProducto">Sponsor 2</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" />
                                <label for="lblCodigoProducto">Cargo</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListCountry" 
                                    optionLabel="Name" optionValue="CountryId" 
                                    placeholder="País" class="w-full"  />
                                <label for="lblUnidadMedida">Plataforma de aprendizaje</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4"></div>

                        <div class="field col-12 md:col-4">
                            <FileUpload name="demo[]" url="/api/upload" @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">
                                <template #empty>
                                    <p>Arrastré aquí el logo de su empresa.</p>
                                </template>
                            </FileUpload>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4"></div>
                    </div>
                </div>

                <Divider layout="horizontal" />
                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="Guardar()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { usePrimeVue } from 'primevue/config';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const oListCountry = ref([
    { CountryId: 1, Name: 'Perú' },
    { CountryId: 2, Name: 'Chile' },
    { CountryId: 3, Name: 'Colombia' },
]);

//#region Eventos
onMounted(() => {
    Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
    CambiarEspanol();
} 

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.choose = "Buscar";
    primevue.config.locale.upload = "Subir";
    primevue.config.locale.cancel = "Cancelar";
}


const BackTo = () => {
    router.push({ path: `/portal/Empresa/Mantenedor` });
}
//#endregion

</script>