export default class TipoProductoService {
    async ListarTipoProducto() {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/tipoproducto');
        return await response.json();
    }

    async ObtenerTipoProducto(id) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/tipoproducto/${id}`);
        return await response.json();
    }
}