<template>
    <div class="grid">
        
        <div class="col-12 lg:col-6 xl:col-12">
            <div class="flex flex-column h-500rem">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">Nombres Completos</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">Apellido Materno y Paterno</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">DNI</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Calendar showIcon iconDisplay="input" 
                                inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                :class="{ 'p-invalid' : fechaFinValido }" />
                            <label for="lblFechaFin">Fecha nacimiento</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">Celular</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">Correo</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId" 
                                placeholder="País" class="w-full"  />
                            <label for="lblUnidadMedida">Departamento</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId" 
                                placeholder="País" class="w-full"  />
                            <label for="lblUnidadMedida">Ciudad</label>
                        </FloatLabel>
                    </div>
                    <Divider layout="horizontal" class="bt-0 mt-0"></Divider>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">N° Pasaporte</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Calendar showIcon iconDisplay="input" 
                                inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                :class="{ 'p-invalid' : fechaFinValido }" />
                            <label for="lblFechaFin">Vencimiento pasaporte</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <InputText id="txtProductoId" type="hidden" />
                            <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                            <label for="lblCodigoProducto">N° Visa</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId" 
                                placeholder="País" class="w-full"  />
                            <label for="lblUnidadMedida">Situación VISA</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Calendar showIcon iconDisplay="input" 
                                inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                :class="{ 'p-invalid' : fechaFinValido }" />
                            <label for="lblFechaFin">Vencimiento VISA</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId"  class="w-full"  />
                            <label for="lblUnidadMedida">Certiadulto/certijoven</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId" class="w-full"  />
                            <label for="lblUnidadMedida">Antecedentes</label>
                        </FloatLabel>
                    </div>
                    <div class="field col-12 md:col-6">
                        <FloatLabel>
                            <Dropdown :options="oListCountry" 
                                optionLabel="Name" optionValue="CountryId" class="w-full"  />
                            <label for="lblUnidadMedida">Estado</label>
                        </FloatLabel>
                    </div>
                </div>
            </div>

            <Divider layout="horizontal" />
            <div class="flex justify-content-end">
                <Button label="Cancelar" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="closeModalHandler"></Button>
                <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="Guardar()" :loading="bCargando" />
            </div>
        </div>

    </div>

</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineEmits } from 'vue';
import { useConfirm } from 'primevue/useconfirm';

const router = useRouter();
const confirm = useConfirm();

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const oListProfession = ref([]);

const emit = defineEmits();

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
    LoadProfession();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadProfession = () => {
    oListProfession.value = [
        {
            ProfessionId: 0,
            Name: 'Todos'
        },
        {
            ProfessionId: 1,
            Name: 'Ing. Mecánica'
        }
    ];
}

const LoadPersonal = () => {
    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            Profession: 'Ing. Mecánica',
            Specialty: 'Metales pesados',
            Experience: '2 años',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            Status: 'Disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 2,
            PersonId: 2,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Ing. Mecánica',
            Specialty: 'Metales ligeros',
            Experience: '2 años y 8 meses',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Acreditado',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 3,
            PersonId: 3,
            DocumentNumber: '70005511',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Ing. Metalurgica',
            Specialty: 'Metales pesados',
            Experience: '1 año',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'No disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 4,
            PersonId: 4,
            DocumentNumber: '70005500',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Administración',
            Specialty: 'Recursos humanos',
            Experience: '5 años',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Vigente',
            PersonalDocument: false,
            Courses: true,
            Emos: false
        }
    ];
}

const closeModalHandler = () => {
  emit('closeModal');
}

const Guardar = () => {}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
