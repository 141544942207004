export default class PagoAtencionService {
    async RegistrarPagoAtencionService(request) {
        const response= await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/nuevopago', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarPagosAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/pagos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async EliminarPagoAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/eliminarpago', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ObtenerPagoAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/pago', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async VerPDFPagosAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/pdf/pdfpago', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.blob();
    }
}