<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado de cursos</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListCoursesStatus"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Status" header="Estado" >
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Status" severity="success" />
                        </template>
                    </Column>
                    <Column field="Type" header="Tipo curso"></Column>
                    <Column field="Exhibit" header="Anexo"></Column>
                    <Column field="VigencyDate" header="F.vigencia"></Column>
                    <Column field="ExhibitSigned" header="Anexo 4 firmado">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.ExhibitSigned, 'pi-times-circle text-red-500': !slotProps.data.ExhibitSigned }"></i>
                        </template>
                    </Column>
                    <Column field="CardAccreditation" header="Tarjeta acreditación cursos especificos">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.CardAccreditation, 'pi-times-circle text-red-500': !slotProps.data.CardAccreditation }"></i>
                            </div>
                        </template>
                    </Column>
                    <Column field="CardNumberBlocked" header="N° tarjeta bloqueo">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.CardNumberBlocked" severity="success" />
                            </div>
                        </template>
                    </Column>
                    <Column field="CardBlocked" header="Tarjeta bloqueo">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.CardBlocked, 'pi-times-circle text-red-500': !slotProps.data.CardBlocked }"></i>
                        </template>
                    </Column>
                    <Column field="AccreditationOperators" header="Acreditación operadores">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.AccreditationOperators" severity="success" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Cursos</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListCourses"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Course" header="Curso" ></Column>
                    <Column field="Mode" header="Modalidad" ></Column>
                    <Column field="Status" header="Estado curso">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Status" severity="success" />
                        </template>
                    </Column>
                    <Column field="IssueDate" header="Fecha emisión"></Column>
                    <Column field="VigencyDate" header="Fecha vigencia"></Column>
                    <Column field="CoursePreReq" header="Curso pre-requisito"></Column>
                    <Column field="StatusPreReq" header="Estado pre-requisito">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.StatusPreReq" severity="success" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Certificaciones</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListCertification"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Course" header="Curso" ></Column>
                    <Column field="Mode" header="Modalidad" ></Column>
                    <Column field="Status" header="Estado curso">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Status" severity="success" />
                        </template>
                    </Column>
                    <Column field="IssueDate" header="Fecha emisión"></Column>
                    <Column field="VigencyDate" header="Fecha vigencia"></Column>
                    <Column field="CoursePreReq" header="Curso pre-requisito"></Column>
                    <Column field="StatusPreReq" header="Estado pre-requisito">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.StatusPreReq" severity="success" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Personal específico(definir perfiles)</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListLicenses"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="License" header="Licencia" ></Column>
                    <Column field="AccreditaarionDrivers" header="Acreditación conductores"></Column>
                    <Column field="TypeLicense" header="Tipo licencia"></Column>
                    <Column field="VigencyDate" header="Fecha Vencimiento"></Column>
                </DataTable>

                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">                        
                        <div class="field col-12 md:col-12 pt-5 mt-5">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Observaciones</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>

            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" />
        </div>

    </div>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListDocumentsPersonal = ref([]);
const oListCoursesStatus = ref([]);
const oListCourses = ref([]);
const oListCertification = ref([]);
const oListLicenses = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {
    oListCoursesStatus.value = [{
        Status:'Vigente',
        Type: 'Presencial',
        Exhibit: '29/05/2028',
        VigencyDate: '29/05/2028',
        ExhibitSigned: true,
        CardAccreditation: true,
        CardNumberBlocked: 'Vigente',
        CardBlocked: true,
        AccreditationOperators: 'Vigente'
    }];

    oListCourses.value = [
        {
            Index: 1,
            Course: 'Grúas de izaje',
            Mode: 'Virtual',
            Status: 'Vigente',
            IssueDate: '29/05/2028',
            VigencyDate: '29/05/2030',
            CoursePreReq: 'Altura',
            StatusPreReq: 'Vigente'
        },
        {
            Index: 2,
            Course: 'Grúas de izaje 2',
            Mode: 'Presencial',
            Status: 'Vigente',
            IssueDate: '29/05/2028',
            VigencyDate: '29/05/2030',
            CoursePreReq: 'Altura',
            StatusPreReq: 'Vigente'
        }
    ];

    oListCertification.value = [
        {
            Index: 1,
            Course: 'Grúas de izaje',
            Mode: 'Virtual',
            Status: 'Vigente',
            IssueDate: '29/05/2028',
            VigencyDate: '29/05/2030',
            CoursePreReq: 'Altura',
            StatusPreReq: 'Vigente'
        },
        {
            Index: 2,
            Course: 'Grúas de izaje 2',
            Mode: 'Virtual',
            Status: 'Vigente',
            IssueDate: '29/05/2028',
            VigencyDate: '29/05/2030',
            CoursePreReq: 'Altura',
            StatusPreReq: 'Vigente'
        }
    ];

    oListLicenses.value = [
        {
            License: 'Licencia interna manejo MRM',
            VigencyDate: '29/05/2030',
            AccreditaarionDrivers: 'definir',
            TypeLicense: 'definir',
            Status: 'Vigente'
        },
        {
            License: 'Licencia interna montacargas',
            VigencyDate: '29/05/2030',
            AccreditaarionDrivers: 'definir',
            TypeLicense: 'definir',
            Status: 'Vigente'
        }
    ];

    oListDocumentsPersonal.value = [
        {
            Index: 1,
            Document: 'Anexo 4',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FDNI.pdf?alt=media&token=39517142-aa67-4f36-a1a2-190b4f3c4bfb',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 2,
            Document: 'ABE',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FHoja%20de%20Vida.pdf?alt=media&token=734674ea-826f-4183-844a-6f2f3805593c',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 3,
            Document: 'ALTURA',
            Url: '',
            LastDate: '01/12/2024',
            Status: false
        },
        {
            Index: 4,
            Document: 'CONFINADO',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 5,
            Document: 'CALIENTE',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 6,
            Document: 'GRÚAS DE IZAJE',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 7,
            Document: 'IPERC',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 8,
            Document: 'Seguridad de molinos',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 9,
            Document: 'Certificados',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 10,
            Document: 'Tarjeta de bloqueo',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 11,
            Document: 'Licencia interna manejo mrm',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        }
    ];
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
