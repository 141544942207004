<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();

const { onMenuToggle } = useLayout();

const username = ref("");
const tipoUsuario = ref("");
const company = ref('');
const country = ref('');
const countryImage = ref('');
const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();

const menu = ref(null);

const overlayMenuItems = ref([
  /*{
    label: "Cambiar contraseña",
    icon: "pi pi-key",
    to: "/system/change-password"
  }*/
]);

const toggleMenu = (event) => {
  menu.value.toggle(event);
};

onMounted(() => {
  bindOutsideClickListener();
  let res = JSON.parse(store.state.datauser);
  if(res){
    username.value = res.user.firstName + " " + res.user.lastName;
    tipoUsuario.value = res.user.userType;
    if(res.Company){
      company.value = res.Company.Name;
      country.value = res.Company.CountryName;
      countryImage.value = res.Company.CountryImage;
    }
  }
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const logoUrl = computed(() => {
  // return `layout/images/${
  //   layoutConfig.darkTheme.value ? "logo-white" : "logo-dark"
  // }.svg`;

  return `${process.env.BASE_URL}layout/images/logo.png`;
});

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
  topbarMenuActive.value = false;
  router.push("/documentation");
};
const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const closeSesion = () => {
  store.commit("logout");
  router.push({ path: "/" });
};

const changeCompany = () => {
  store.commit('selectedCompany', null);
  router.push({ path: "/seleccion" });
};
</script>

<template>
  <div class="layout-topbar">
    <button v-if="company"
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <router-link to="/portal" class="layout-topbar-logo">
      <img :src="logoUrl" alt="logo" />
      <span></span>
    </router-link>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <!-- <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
                <i class="pi pi-calendar"></i>
                <span>Calendar</span>
            </button> -->

      <Menu ref="menu" :model="overlayMenuItems" :popup="true" style="font-size: 9pt;">
        <template #start>
          <button
            class="w-full p-link flex align-items-center p-2 pl-6 text-color hover:surface-200 border-noround"
          >
            <div class="flex flex-column align-items-center">
              <span class="font-bold">{{ username }}</span>
              <span>{{ tipoUsuario }}</span>
              <Divider layout="horizontal"  v-if="company" />
              <div class="flex align-items-center"  v-if="company">
                <img :src="countryImage" :alt="country" width="20" class="mr-2">
                <span>{{ country }}</span>
              </div>
              <Divider layout="horizontal" />
            </div>
          </button>
        </template>
        
        <template #end>
          <button
            @click="changeCompany"  v-if="company"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround">
            <i class="pi pi-sync" />
            <span class="ml-2">{{ company }}</span>
          </button>
          <button
            @click="closeSesion"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround">
            <i class="pi pi-sign-out" />
            <span class="ml-2">Cerrar sesión</span>
          </button>
        </template>

      </Menu>
       <button @click="toggleMenu" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>  

      <!-- <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
      <Button
        type="button"
        label="Perfil"
        icon="pi pi-angle-down"
        @click="toggleMenu"
        style="width: auto"
      /> -->

      <!-- <button @click="onSettingsClick()" class="p-link layout-topbar-button">
                <i class="pi pi-cog"></i>
                <span>Settings</span>
            </button> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout-topbar .layout-menu-button {
    margin-left: 0;
}
</style>
