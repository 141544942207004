const Tablas = {
    TipoDocumentoPersona: {
        Codigo: 'T0001',
        Tabla: 'TipoDocumentoPersona'
    },
    GeneroPersona: {
        Codigo: 'T0002',
        Tabla: 'GeneroPersona'
    },
    EstadoCivil: {
        Codigo: 'T0003',
        Tabla: 'EstadoCivil'
    },
    EstadoFichaAtencion: {
        Codigo: 'T0006',
        Tabla: 'EstadoFichaAtencion'
    },
    EstadoCitaAtencion: {
        Codigo: 'T0007',
        Tabla: 'EstadoCitaAtencion'
    },
    TipoFotoCita: {
        Codigo: 'T0008',
        Tabla: 'TipoFotoCitaAtencion'
    }
};

export default Tablas;