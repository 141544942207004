<template>
  <RouterView/>
</template>
<script>

export default {
  name: 'App',
  created() {
    // Inicializa el estado de Vuex a partir de `localStorage` o de otra fuente de datos persistente
    this.$store.commit('initialiseStore');
  }
};
</script>
<style scoped>

</style>
