export default class ProductoService {
    async ListarProductosService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/listarproducto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async RegistrarProductoService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/nuevoproducto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }
    
    async ActivarDesactivarProductoService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/activardesactivarproducto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ObtenerProductoService(id) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/producto/${id}`);        
        return await response.json();
    }
}