<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nuevo Proyecto</h1>
                    </template>

                    <template #end>
                    </template>
                </Toolbar>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListCompany" 
                                    optionLabel="Name" optionValue="CompanyId" class="w-full"  />
                                <label for="lblUnidadMedida">Empresa</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-8"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtProductoId" type="hidden" />
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Código</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" readonly />
                                <label for="lblCodigoProducto">Nombre Proyecto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-8">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Descripción</label>
                            </FloatLabel>
                        </div>                        
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-3">
                            <FloatLabel>
                                <Dropdown :options="oListCompany" 
                                    optionLabel="Name" optionValue="CompanyId" class="w-full"  />
                                <label for="lblUnidadMedida">Profesión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-3">
                            <FloatLabel>
                                <Dropdown :options="oListCompany" 
                                    optionLabel="Name" optionValue="CompanyId" class="w-full"  />
                                <label for="lblUnidadMedida">Especialidad</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2">
                            <FloatLabel>
                                <InputNumber v-model="QuantityReq" inputId="minmax" :min="0" :max="100" />
                                <label for="lblUnidadMedida">Cant. Req.</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                    </div>
                </div>

                <Divider layout="horizontal" />
                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="Guardar()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const oListCompany = ref([
    {
        Name: 'Yanacocha',
        CompanyId: 1
    }
]);
const QuantityReq = ref(0);
//#region Eventos
onMounted(() => {
    Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
    
} 

const BackTo = () => {
    //router.push({ path: `/portal/Servicio/Mantenedor` });
    router.go(-1)
}
//#endregion

</script>