<template>
    <div class="grid">
        
        <div class="col-12 lg:col-6 xl:col-12">
            <div class="card mb-1 pb-3">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-3 pb-0 mb-0">
                            <FloatLabel>
                                <Dropdown :options="oListProfession" v-model="oFilters.ProfessionId"
                                    optionLabel="Name" optionValue="ProfessionId" class="w-full" @change="LoadSpecialty()" />
                                <label for="lblProfession">Profesión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-3 pb-0 mb-0">
                            <FloatLabel>
                                <Dropdown :options="oListSpecialty" v-model="oFilters.SpecialtyId"
                                    optionLabel="Name" optionValue="SpecialtyId" class="w-full" @change="ClearReport()" />
                                <label for="lblSpecialty">Especialidad</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2 mb-0">
                            <Button label="Aplicar" icon="pi pi-search" class="p-button-success mr-2" @click="LoadPersonal()" />
                        </div>
                        <div class="field col-12 md:col-2 mb-0"></div>
                        <div class="field col-12 md:col-2 mb-0">
                            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NewPersonal()" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <DataTable ref="dtPersonal" 
                    :value="oListPersonal"
                    dataKey="PersonId"
                    v-model:selection="oListSelected"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Seleccione un colaborador para agregarlo</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="Index" header="#"></Column>
                    <Column field="DocumentNumber" header="DNI" >
                        <template #body="slotProps">
                            <b v-tooltip.top="`Fecha Vencimiento:\n${ slotProps.data.ExpirationDate }`">{{ slotProps.data.DocumentNumber }}</b>
                        </template>
                    </Column>
                    <Column field="FullName" header="Nombre y Apellido"></Column>
                    <Column field="Profession" header="Profesión"></Column>
                    <Column field="Specialty" header="Especialidad"></Column>
                    <Column field="Experience" header="Experiencia"></Column>
                    <Column field="TypeEmployee" header="Tipo Contrato"></Column>
                    <Column field="MinCondition" header="Tipo Ingreso"></Column>
                </DataTable>
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Cancelar" severity="secondary" @click="closeModalHandler"></Button>
                <Button type="button" label="Aceptar" class="p-button-primary mr-2" @click="AddPersonal()"></Button>
            </div>
        </div>

    </div>

</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineEmits } from 'vue';
import { useConfirm } from 'primevue/useconfirm';

const router = useRouter();
const confirm = useConfirm();

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const oListProfession = ref([]);
const oListSpecialty = ref([]);
const oListSelected = ref([]);
const oFilters = ref({
    ProfessionId: 0,
    SpecialtyId: 0
});

const emit = defineEmits();

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
    LoadProfession();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadProfession = () => {
    oListProfession.value = [
        {
            ProfessionId: 0,
            Name: 'Todos'
        },
        {
            ProfessionId: 1,
            Name: 'Ing. Mecánica'
        }
    ];
}

const LoadSpecialty = () => {
    oListSpecialty.value = [
        {
            SpecialtyId: 0,
            Name: 'Todos'
        },
        {
            SpecialtyId: 1,
            Name: 'Metales Pesados'
        }
    ];
}

const LoadPersonal = () => {
    oListPersonal.value = [
        {
            Index: 1,
            PersonId: 1,
            DocumentNumber: '74125526',
            ExpirationDate: '2024-10-01',
            FullName: 'José Ruiz',
            Profession: 'Ing. Mecánica',
            Specialty: 'Metales pesados',
            Experience: '2 años',
            TypeEmployee: 'Temporal',
            MinCondition: 'Nuevo',
            Status: 'Disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 2,
            PersonId: 2,
            DocumentNumber: '70005526',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Ing. Mecánica',
            Specialty: 'Metales ligeros',
            Experience: '2 años y 8 meses',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Acreditado',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 3,
            PersonId: 3,
            DocumentNumber: '70005511',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Ing. Metalurgica',
            Specialty: 'Metales pesados',
            Experience: '1 año',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'No disponible',
            PersonalDocument: true,
            Courses: true,
            Emos: false
        },
        {
            Index: 4,
            PersonId: 4,
            DocumentNumber: '70005500',
            ExpirationDate: '2024-09-01',
            FullName: 'Davod Ruiz',
            Profession: 'Administración',
            Specialty: 'Recursos humanos',
            Experience: '5 años',
            TypeEmployee: 'Planilla',
            MinCondition: 'Reingresante',
            Status: 'Vigente',
            PersonalDocument: false,
            Courses: true,
            Emos: false
        }
    ];
}

const ClearReport = () => {

}

const AddPersonal = () => {
    if(oListSelected.value.length > 0){
        emit('sendList', oListSelected);
        closeModalHandler();
    }
    else{
        confirm.require({
            header: 'Alerta',
            message: 'Debe seleccionar al menos un colaborador',
            acceptLabel: 'Aceptar',
            acceptClass: 'p-button-primary',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log('El usuario aceptó');
            }
        });

    }
}

const closeModalHandler = () => {
  emit('closeModal');
}

const NewPersonal = () => {
    router.push({ path: `/portal/Accreditation/Personal` });
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
