<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoProducto" />
                        <Button label="Nueva Ficha" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevaFicha" />
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
                <DataTable ref="dtProductos" 
                    :value="oListaProductos"
                    dataKey="ProductoId"
                    :rowHover="true"
                    :paginator="true" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">{{ codigoTipoProducto }} - {{ nombreTipoProducto }}</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column field="Codigo" header="Código" :sortable="true"></Column>
                    <Column field="Nombre" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.Nombre }}</b><br/>
                            {{ slotProps.data.Categoria.Nombre }}
                        </template>
                    </Column>
                    
                    <Column field="PrecioVenta" header="Precio" :sortable="true" v-if="TipoProducto.TienePrecioVenta">
                        <template #body="slotProps">
                            S/. {{ Utilitario.formatearDecimal(slotProps.data.PrecioVenta) }}
                        </template>
                    </Column>
                    <Column field="Estado" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.Estado" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.Estado" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" @click="EditarProducto(slotProps.data.ProductoId)" />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.Estado ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.Estado ? 'p-button-danger' : 'p-button-secondary'" 
                                    @click="ActivarDesactivarProducto(slotProps.data.ProductoId, slotProps.data.Codigo, slotProps.data.Estado)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script setup>
import ProductoService from '@/service/ProductoService';
import TipoProductoService from '@/service/TipoProductoService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//#region Variables
const route = useRoute();
const router = useRouter();
const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const productoService = new ProductoService();
const tipoProductoService = new TipoProductoService();
const usuarioStore = JSON.parse(store.state.datauser);

const TipoProducto = ref({});
const nombreTipoProducto = ref('');
const codigoTipoProducto = ref('');
const oListaProductos = ref(null);
const bCargando = ref(false);
const filtros = ref({});
//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
});

onBeforeMount(() => {
    inicializarFiltros();
});
//#endregion

//#region Metodos
const Inicializador = () => {
    const id = Crypto.Desencriptar(route.params.id);
    bCargando.value= true;
    ObtenerTipoProducto(id);
    ListarProductos(id);
}

const inicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    router.push({ path: `/portal/producto` });
}

const NuevoProducto = () => {
    router.push(`/portal/nuevoproducto/${encodeURIComponent(route.params.id)}`);
}

const NuevaFicha = () => {
    let p1 = Crypto.Encriptar(1);
    let p2 = Crypto.Encriptar(74);
    router.push(`/portal/fichaatencion/${encodeURIComponent(p1)}/${encodeURIComponent(p2)}`);
}

const EditarProducto = (productoId) => {
    router.push(`/portal/nuevoproducto/${encodeURIComponent(route.params.id)}/${encodeURIComponent(Crypto.Encriptar(productoId))}`);
}

const ActivarDesactivarProducto = (parametro, codigoProducto, estado) => {
    const nombreEstado = estado ? 'Desactivar' : 'Activar';
    const nombreEstadoConfirmacion = estado ? 'desactivó' : 'activó';
    confirm.require({
        message: `¿Está seguro de ${nombreEstado} el ${nombreTipoProducto.value} ${codigoProducto}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    ProductoId: parametro,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };

                const response = await productoService.ActivarDesactivarProductoService(request).then();
                if(response.Status == 201){
                    toast.add({ severity: 'success', summary: 'Confirmación', detail: `Se ${nombreEstadoConfirmacion} el ${nombreTipoProducto.value} ${codigoProducto} correctamente`, life: 6000});
                    ListarProductos(Crypto.Desencriptar(route.params.id));
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al '+nombreEstado+' el paciente:', error);
            }
        }
    });
}

const ObtenerTipoProducto = (id) => {
    tipoProductoService.ObtenerTipoProducto(id).then(
        response => {
            TipoProducto.value = response.Data;
            nombreTipoProducto.value = response.Data.Nombre;
            codigoTipoProducto.value = response.Data.Codigo;
        }
    );
}

const ListarProductos = (id) => {
    const request = {
        TipoProducto: {
            TipoProductoId: id
        } ,
        Establecimiento : {
            EstablecimientoId: usuarioStore.User.EstablecimientoId
        }
    };

    productoService.ListarProductosService(request).then(
        response => {
            oListaProductos.value = response.Data;
            bCargando.value = false;      
        }
    );
}
//#endregion

</script>