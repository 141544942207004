<template>
    <div class="layout-wrapper">
        <app-topbar></app-topbar>
        <div class="layout-main-container">
            <div class="layout-main">

                <h4 class="text-center">Seleccionar país</h4>
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-4" v-for="(item) in oListCountries" :key="item.CountryId">
                        <Card @click="SeleccionarPais(item)" class="pointer" :class="{'seleccion-card': item.CountryId === selectedCountry}">
                            <template #header>
                                <div class="pt-5 pb-3" style="text-align: center;">
                                    <Avatar :image="item.Icono" class="mr-2" size="xlarge" shape="circle" />
                                    <p style="font-size: 13pt; padding-top: 10px;">{{ item.Name }}</p>
                                </div>
                            </template>
                        </Card>
                    </div>
                </div>

                <div class="grid mt-5" v-if="oListCompanySelected.length > 0">
                    <div class="col-12">
                        <div class="card">
                            <p>Seleccionar empresa</p>
                            <DataTable ref="dtCompany" 
                                :value="oListCompanySelected"
                                dataKey="ProductoId"
                                :rowHover="true"
                                :loading="bCargando"
                                :filters="filtros"
                                class="p-datatable-sm"
                            >
                                <Column field="Name" header="Empresa"></Column>
                                <Column field="Services" header="Proyectos"></Column>
                                <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                    <template #body="slotProps">
                                        <Button label="Seleccionar" severity="secondary" @click="SelectedCompany(slotProps.data)" />        
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask"></div>
    </div>
</template>


<script setup>
import AppTopbar from "./../layout/AppTopbar.vue";
import AppFooter from './../layout/AppFooter.vue';
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


const router = useRouter();
const store = useStore();

const bCargando = ref(false);
const filtros = ref({});
const selectedCountry = ref(0);
const oListCountries = ref([
    { CountryId: 1, Name: 'Perú', Description: '', Icono: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pais%2Fperu.png?alt=media&token=1e1fcd31-2f48-494f-b049-0f728aac84e2' },
    { CountryId: 2, Name: 'Ecuador', Description: '', Icono: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pais%2Fecuador.png?alt=media&token=c515a510-d6b9-4ad9-a3d7-bc883f224fc6' },
    { CountryId: 3, Name: 'Chile', Description: '', Icono: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pais%2Fchile.png?alt=media&token=393a75e4-abdc-4c3b-aec9-222b6cf43d61' }
]);

const oListCompany = ref([
    { Code: 'E00001', Name: 'Yanacocha', CountryId: 1, Services: 5, Image: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/empresas%2Fbarrick.png?alt=media&token=856b90f3-95b3-41e2-9d91-b01179f91c57' },
    { Code: 'E00002', Name: 'Hudbay', CountryId: 1, Services: 9, Image: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/empresas%2Froyal.png?alt=media&token=eed80dc8-946c-4d56-afdd-2e6ff30bf092'  },
    { Code: 'E00003', Name: 'Empresa 3', CountryId: 2, Services: 7, Image: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/empresas%2Fbarrick.png?alt=media&token=856b90f3-95b3-41e2-9d91-b01179f91c57'  },
    { Code: 'E00004', Name: 'Empresa 4', CountryId: 3, Services: 11, Image: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/empresas%2Fbarrick.png?alt=media&token=856b90f3-95b3-41e2-9d91-b01179f91c57'  },
    { Code: 'E00005', Name: 'Empresa 5', CountryId: 3, Services: 3, Image: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/empresas%2Froyal.png?alt=media&token=eed80dc8-946c-4d56-afdd-2e6ff30bf092'  }
]);

const oListCompanySelected = ref([]);

const SeleccionarPais = (pais) => {
    selectedCountry.value = pais.CountryId;
    oListCompanySelected.value = oListCompany.value.filter(x => x.CountryId == pais.CountryId);
}

const SelectedCompany = (company) => {
    console.log(company);
    if(company){
        const country = oListCountries.value.find(x => x.CountryId == company.CountryId);
        const newData = {
            ...company,
            CountryName: country.Name,
            CountryImage: country.Icono
        }
        store.commit('selectedCompany', newData);
    }
    else{
        store.commit('selectedCompany', null);
    }

    router.push({ path: `/portal` });
}
</script>

<style scoped>
.layout-main-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
}

.layout-main {
  width: 50%; /* Ancho del 50% */
}

</style>