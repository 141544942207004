import Enums from '@/utilitarios/Enums'

export default class ConstanteService {
    async ListarConstanteTablaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarTipoDocumentosService() {
        const request = {
                "CodigoTabla": Enums.TipoDocumentoPersona.Codigo,
                "NombreTabla": Enums.TipoDocumentoPersona.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                TipoDocumentoId: item.ValorTexto1,
                Nombre: item.NombreConstante,
                Descripcion: item.DescripcionConstante,
                NumeroCaracteres: item.ValorNumerico1
            };
        });

        return resultado;
    }

    async ListarGeneroService() {
        const request = {
                "CodigoTabla": Enums.GeneroPersona.Codigo,
                "NombreTabla": Enums.GeneroPersona.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                Codigo: item.ValorTexto1,
                Nombre: item.NombreConstante
            };
        });

        return resultado;
    }

    async ListarEstadoCivilService() {
        const request = {
                "CodigoTabla": Enums.EstadoCivil.Codigo,
                "NombreTabla": Enums.EstadoCivil.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                Codigo: item.ValorTexto1,
                Nombre: item.NombreConstante
            };
        });

        return resultado;
    }

    async ListarEstadoFichaAtencionService() {
        const request = {
                "CodigoTabla": Enums.EstadoFichaAtencion.Codigo,
                "NombreTabla": Enums.EstadoFichaAtencion.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                Codigo: item.ValorNumerico1,
                Nombre: item.NombreConstante
            };
        });

        return resultado;
    }

    async ListarEstadoCitaAtencionService() {
        const request = {
                "CodigoTabla": Enums.EstadoCitaAtencion.Codigo,
                "NombreTabla": Enums.EstadoCitaAtencion.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                Codigo: item.ValorNumerico1,
                Nombre: item.NombreConstante
            };
        });

        return resultado;
    }

    async ListarTipoFotoCitaAtencionService() {
        const request = {
                "CodigoTabla": Enums.TipoFotoCita.Codigo,
                "NombreTabla": Enums.TipoFotoCita.Tabla
        };
        
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/constante/constantes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        const data = await response.json();
        const resultado = data.Data.map(item => {
            return {
                Codigo: item.ValorNumerico1,
                Nombre: item.NombreConstante
            };
        });

        return resultado;
    }
}