<script setup>
import { ref, onMounted } from "vue";
import AppMenuItem from "./AppMenuItem.vue";
import { useStore } from "vuex";

const store = useStore();

const model = ref([]);

const Fn_Childs = (idMenuParent, data) => {
  let item = data
    .filter((d) => d.idMenuParent == idMenuParent)
    .map((d2) => {
      return {
        label: d2.name,
        icon: d2.icon ? d2.icon : "",
        to: d2.to ? d2.to : "#",
        items: Fn_Childs(d2.idMenu, data),
      };
    });
  return item;
};

onMounted(() => {
  let dUser = JSON.parse(store.state.datauser);
  
  let menuFijo = [
  {
    "MenuId": 1,
    "MenuPadreId": 0,
    "Nivel": 0,
    "Nombre": "INICIO",
    "Icono": "",
    "Redireccion": "/portal/"
  },
  {
    "MenuId": 2,
    "MenuPadreId": 1,
    "Nivel": 1,
    "Nombre": "Panel General",
    "Icono": "pi pi-home",
    "Redireccion": "/portal"
  },
  {
    "MenuId": 20,
    "MenuPadreId": 1,
    "Nivel": 1,
    "Nombre": "Dashboard",
    "Icono": "pi pi-home",
    "Redireccion": "/system-dashboard"
  },
  {
    "MenuId": 3,
    "MenuPadreId": 0,
    "Nivel": 0,
    "Nombre": "MÓDULO DE ACREDITACIÓN",
    "Icono": "pi pi-heart",
    "Redireccion": "/portal/"
  },
  {
    "MenuId": 4,
    "MenuPadreId": 3,
    "Nivel": 1,
    "Nombre": "Selección de personal",
    "Icono": "pi pi-users",
    "Redireccion": ""
  },
  {
    "MenuId": 5,
    "MenuPadreId": 3,
    "Nivel": 1,
    "Nombre": "Gestión de cursos",
    "Icono": "pi pi-book",
    "Redireccion": ""
  },
  {
    "MenuId": 6,
    "MenuPadreId": 3,
    "Nivel": 1,
    "Nombre": "Gestión de EMOs",
    "Icono": "pi pi-heart",
    "Redireccion": ""
  },
  {
    "MenuId": 7,
    "MenuPadreId": 3,
    "Nivel": 1,
    "Nombre": "Gestión de contratción",
    "Icono": "pi pi-file-edit",
    "Redireccion": ""
  },
  {
    "MenuId": 8,
    "MenuPadreId": 0,
    "Nivel": 0,
    "Nombre": "Mantenedor",
    "Icono": "pi pi-heart",
    "Redireccion": "/portal/"
  },
  {
    "MenuId": 9,
    "MenuPadreId": 8,
    "Nivel": 1,
    "Nombre": "Mantenedores",
    "Icono": "pi pi-cog",
    "Redireccion": ""
  },
  {
    "MenuId": 10,
    "MenuPadreId": 9,
    "Nivel": 2,
    "Nombre": "Empresa",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 11,
    "MenuPadreId": 9,
    "Nivel": 2,
    "Nombre": "Servicio",
    "Icono": "",
    "Redireccion": "/portal/Servicio/Mantenedor"
  },
  {
    "MenuId": 12,
    "MenuPadreId": 9,
    "Nivel": 2,
    "Nombre": "Cursos",
    "Icono": "",
    "Redireccion": "/portal/Cursos/Mantenedor"
  },
  {
    "MenuId": 13,
    "MenuPadreId": 9,
    "Nivel": 2,
    "Nombre": "EMOS",
    "Icono": "",
    "Redireccion": "/portal/Emos/Mantenedor"
  },
  {
    "MenuId": 14,
    "MenuPadreId": 9,
    "Nivel": 2,
    "Nombre": "Usuario",
    "Icono": "",
    "Redireccion": "/portal/security/user"
  },

  {
    "MenuId": 15,
    "MenuPadreId": 0,
    "Nivel": 0,
    "Nombre": "Reportes",
    "Icono": "pi pi-heart",
    "Redireccion": "/portal/"
  },
  {
    "MenuId": 16,
    "MenuPadreId": 15,
    "Nivel": 1,
    "Nombre": "Reportes",
    "Icono": "pi pi-chart-line",
    "Redireccion": ""
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por trabajador",
    "Icono": "",
    "Redireccion": "/portal/Reports/Employee"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "General de curso",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Personal acreditado",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por EMO",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por curso",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por pasajes",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por contratos",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  },
  {
    "MenuId": 17,
    "MenuPadreId": 16,
    "Nivel": 2,
    "Nombre": "Por lugar de origen",
    "Icono": "",
    "Redireccion": "/portal/Empresa/Mantenedor"
  }
];

  if(dUser){
    let menuArray = dUser.menuOptions.filter((d) => d.level == 0).map((d) => {
      return {
        label: d.name,
        items: Fn_Childs(d.idMenu, dUser.menuOptions),
      };
    });

    for (let i = 0; i < menuArray.length; i++) {
      if(menuArray[i].items.length > 0){
        for(let j = 0; j < menuArray[i].items.length; j++){
          if(menuArray[i].items[j].items.length > 0){
            for(let k = 0; k < menuArray[i].items[j].items.length; k++){
              if(menuArray[i].items[j].items[k].items.length){
                //empty
              }else{
                delete menuArray[i].items[j].items[k].items
              }
            }
          }else{
            delete menuArray[i].items[j].items
          }
        }
      }else{
        delete menuArray[i].items;
      }
    }

    model.value = menuArray;
  }

});
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
    <!-- <li>
            <a href="https://www.primefaces.org/primeblocks-vue/#/" target="_blank">
                <img src="/layout/images/banner-primeblocks.png" alt="Prime Blocks" class="w-full mt-3" />
            </a>
        </li> -->
  </ul>
</template>

<style lang="scss" scoped></style>
