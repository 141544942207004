<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenCompanyNew" />
                    </template>
                </Toolbar>
                
                <DataTable ref="dtCompany" 
                    :value="oListCompany"
                    dataKey="CompanyId"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Emos - Examen Médico</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column field="Code" header="Código" :sortable="true"></Column>
                    <Column field="Name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.Name }}</b><br/>
                        </template>
                    </Column>
                    
                    <Column field="Status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.Status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.Status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.Status ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.Status ? 'p-button-danger' : 'p-button-secondary'"  />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const bCargando = ref(false);
const filtros = ref({});

const oListCompany = ref([
    { CompanyId : 1, Code: '0001', Name: 'Empresa 1', Status: true },
    { CompanyId : 2, Code: '0002', Name: 'Empresa 2', Status: true },
    { CompanyId : 3, Code: '0003', Name: 'Empresa 3', Status: true },
    { CompanyId : 4, Code: '0004', Name: 'Empresa 4', Status: true },
    { CompanyId : 5, Code: '0005', Name: 'Empresa 5', Status: true },
    { CompanyId : 6, Code: '0006', Name: 'Empresa 6', Status: false },
    { CompanyId : 7, Code: '0007', Name: 'Empresa 7', Status: false },
    { CompanyId : 8, Code: '0008', Name: 'Empresa 8', Status: true },
    { CompanyId : 9, Code: '0009', Name: 'Empresa 9', Status: true },
    { CompanyId : 10, Code: '00010', Name: 'Empresa 10', Status: true },
    { CompanyId : 11, Code: '00011', Name: 'Empresa 11', Status: true }
]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenCompanyNew = () => {
    router.push({ path: `/portal/Emos/Nuevo` });
}
//#endregion
</script>