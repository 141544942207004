<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="grid">
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card mb-0 pb-0">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <!--<span class="block font-semibold">Cumplimiento</span>-->
                                <div class="text-900 font-bold text-2xl"><b>S/. 400.00</b></div>
                                <div class="font-medium text-sm">Gastos en soles</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-file text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card mb-0 pb-0">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>$ 120.50</b></div>
                                <div class="font-medium text-sm">Gastos en dólares</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-file text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Tramos de transporte</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar itierario`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListItinerary"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar itinerario`" class="p-button-success ml-2" />
                        </template>
                    </Column>
                    <Column field="MeansTransport" header="Medio transporte">
                        <template #body="slotProps">
                            {{ slotProps.data.MeansTransport }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">Tramo {{ slotProps.data.Index }}</div>
                        </template>
                    </Column>
                    <Column field="DepartureCity" header="Partida">
                        <template #body="slotProps">
                            {{ slotProps.data.DepartureCity }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.DepartureDateTime }}</div>
                        </template>
                    </Column>
                    <Column field="DestinationCity" header="Destino" >
                        <template #body="slotProps">
                            {{ slotProps.data.DestinationCity }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.DestinationDateTime }}</div>
                        </template>
                    </Column>
                    <Column field="Investment" header="Inversión" >
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Investment }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.Currency }}</div>
                        </template>
                    </Column>
                    <Column field="Status" header="Estado" >
                        <template #body="slotProps">
                            <div style="padding-bottom: 1rem;">
                                <Tag :value="slotProps.data.Status" severity="success" />
                            </div>                            
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Viáticos</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar itierario`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListTravelExpenses"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar itinerario`" class="p-button-success ml-2" />
                        </template>
                    </Column>
                    <Column field="Section" header="Tramo">
                        <template #body="slotProps">
                            {{ slotProps.data.Section }}
                        </template>
                    </Column>
                    <Column field="Breakfast" header="Desayuno">
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Breakfast }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.Currency }}</div>
                        </template>
                    </Column>
                    <Column field="Lunch" header="Almuerzo" >
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Lunch }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.Currency }}</div>
                        </template>
                    </Column>
                    <Column field="Dinner" header="Cena" >
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Dinner }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.Currency }}</div>
                        </template>
                    </Column>
                    <Column field="Mobility" header="Movilidad" >
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Mobility }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.Currency }}</div>
                        </template>
                    </Column>
                    <Column field="Total" header="Total" >
                        <template #body="slotProps">
                            {{ slotProps.data.Symbol }} {{ slotProps.data.Total }}
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListItinerary = ref([]);
const oListTravelExpenses = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {
    oListItinerary.value = [{
        Index: 1,
        MeansTransport: 'Aéreo',
        DepartureCity: 'Trujillo',
        DepartureDateTime: '01/10/2024 10:50',
        DestinationCity: 'Arequipa',
        DestinationDateTime: '01/10/2024 14:30',
        Investment: '50.00',
        Symbol: '$',
        Currency: 'Dólares',
        Status: 'Programado'
    },
    {
        Index: 2,
        MeansTransport: 'Aéreo',
        DepartureCity: 'Arequipa',
        DepartureDateTime: '01/10/2024 10:50',
        DestinationCity: 'Madre de Dios',
        DestinationDateTime: '01/10/2024 14:30',
        Investment: '70.50',
        Symbol: '$',
        Currency: 'Dólares',
        Status: 'Programado'
    },
    {
        Index: 3,
        MeansTransport: 'Terrestre',
        DepartureCity: 'Madre de Dios',
        DepartureDateTime: '01/10/2024 10:50',
        DestinationCity: 'Yanacocha',
        DestinationDateTime: '01/10/2024 14:30',
        Investment: '150.00',
        Symbol: 'S/.',
        Currency: 'Soles',
        Status: 'Programado'
    }];

    oListTravelExpenses.value = [
        {
            Section: 'Izaje',
            Currency: 'Soles',
            Symbol: 'S/.',
            Breakfast: '35',
            Lunch: '35',
            Dinner: '30',
            Mobility: '25',
            Total: '125.00'
        },
        {
            Section: 'Regreso',
            Currency: 'Soles',
            Symbol: 'S/.',
            Breakfast: '35',
            Lunch: '35',
            Dinner: '30',
            Mobility: '25',
            Total: '125.00'
        }
    ];
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
